import React from "react";
import { FaHeart } from "react-icons/fa";
import {
  AboutReviewCol,
  AboutReviewContainer,
  AboutReviewImage,
  AboutReviewRow,
  AboutReviewsRow,
  AboutReviewText,
  AboutReviewTitle,
} from "./aboutReview.styles";

export default function AboutReview() {
  return (
    <>
      <AboutReviewContainer>
        <AboutReviewCol>
          <AboutReviewTitle>What Our Christians Say</AboutReviewTitle>
          <AboutReviewText>Know your position in Christ</AboutReviewText>
        </AboutReviewCol>
        <div style={{ display: "flex", justifyContent: "center", margin: "1rem 0" }}>
          <FaHeart
            color="red"
            size={52}
            style={{
              cursor: "pointer",
              transition: "transform 0.3s ease",
            }}
            onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.2)")}
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
          />
        </div>
        <AboutReviewCol>
          <AboutReviewTitle>
            "The Empowerment Revolution has greatly trained us to be the people
            we are today. We are transformed, enlightened, enriched, and
            empowered by the renewal of our mind and meditation on God's word."
          </AboutReviewTitle>
          <AboutReviewsRow>
            <AboutReviewRow>
              <AboutReviewImage />
              <AboutReviewCol>
                <AboutReviewTitle>Sis Mary Anna</AboutReviewTitle>
                <AboutReviewText>Worship Minister</AboutReviewText>
              </AboutReviewCol>
            </AboutReviewRow>
            <AboutReviewRow>
              <AboutReviewImage />
              <AboutReviewCol>
                <AboutReviewTitle>Pst MaxWell Dean</AboutReviewTitle>
                <AboutReviewText>Associate Pastor</AboutReviewText>
              </AboutReviewCol>
            </AboutReviewRow>
            <AboutReviewRow>
              <AboutReviewImage />
              <AboutReviewCol>
                <AboutReviewTitle>Bro Ikome Clinton</AboutReviewTitle>
                <AboutReviewText>Sanitation Worker</AboutReviewText>
              </AboutReviewCol>
            </AboutReviewRow>
          </AboutReviewsRow>
        </AboutReviewCol>
      </AboutReviewContainer>
    </>
  );
}