import { AboutRowCol, AboutRowContainer, AboutRowLine, AboutRowNumber, AboutRowtext } from "./aboutRow.styles";

export default function AboutRow(){
    return(<>
    <AboutRowContainer>
        <AboutRowCol>
            <AboutRowNumber>200</AboutRowNumber>
            <AboutRowtext>Pastors</AboutRowtext>
            </AboutRowCol>
            <AboutRowCol>
            <AboutRowNumber>30</AboutRowNumber>
            <AboutRowtext>Administrators</AboutRowtext>
            </AboutRowCol>
            <AboutRowCol>
            <AboutRowNumber>5K+</AboutRowNumber>
            <AboutRowtext>Christians</AboutRowtext>
            </AboutRowCol>
            <AboutRowCol>
            <AboutRowNumber>12K+</AboutRowNumber>
            <AboutRowtext>Followers</AboutRowtext>
            </AboutRowCol>
           
            
            
            
            
            
            
            
            
            </AboutRowContainer></>)
}