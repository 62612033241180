import React, { useState } from "react";
import {
  FaEnvelope,
  FaGlobe,
  FaInfoCircle,
  FaMoneyBill,
  FaPhone,
} from "react-icons/fa";
import {
  Box,
  Button,
  Card,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
  styled,
  keyframes, // Import this for proper typing
} from "@mui/material";

import img1 from "../../../assets/images/tec.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GiveContainer = styled(Card)(({ theme }) => ({
  margin: "2% auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "800px",
  width: "90%",
  borderRadius: "20px",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  animation: `${fadeIn} 1s ease-out`,
}));

const GiveHeader = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "250px",
  backgroundImage: `url(${img1})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  borderRadius: "20px 20px 0 0",
  backgroundRepeat:"no-repeat"
}));

const GiveTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
  color: "#4A00E0", // Gradient purple
  textAlign: "center",
  margin: "2% 0",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.8rem",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "80%",
  margin: "1% 0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4A00E0",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7F00FF",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "80%",
  margin: "1% 0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#4A00E0",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#7F00FF",
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  marginTop: "3%",
  fontSize: "1.2rem",
  width: "50%",
  borderRadius: "30px",
  padding: "10px 20px",
  background: "linear-gradient(to right, #7F00FF, #E100FF)",
  color: "white",
  textTransform: "none",
  fontWeight: "bold",
  boxShadow: "0px 4px 10px rgba(127, 0, 255, 0.5)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    background: "linear-gradient(to right, #4A00E0, #7F00FF)",
  },
}));

const GiveFooter = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  textAlign: "center",
  color: "#555",
  fontSize: "0.9rem",
}));

export default function GiveScreen() {
  const [currency, setCurrency] = useState("");
  const [category, setCategory] = useState("");

  // Use SelectChangeEvent from MUI instead of React.ChangeEvent
  const handleCurrencyChange = (event: SelectChangeEvent<unknown>) => {
    setCurrency(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent<unknown>) => {
    setCategory(event.target.value as string);
  };

  return (
    <GiveContainer>
      <GiveHeader />
      <GiveTitle>Make Your Giving Here</GiveTitle>
      <StyledTextField
        placeholder="Full Name"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaInfoCircle style={{ color: "#7F00FF" }} />
            </InputAdornment>
          ),
        }}
      />
      <StyledTextField
        placeholder="Email Address"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaEnvelope style={{ color: "#7F00FF" }} />
            </InputAdornment>
          ),
        }}
      />
      <StyledTextField
        placeholder="Phone Number"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaPhone style={{ color: "#7F00FF" }} />
            </InputAdornment>
          ),
        }}
      />
      <StyledTextField
        placeholder="Amount"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaMoneyBill style={{ color: "#7F00FF" }} />
            </InputAdornment>
          ),
        }}
      />
      <StyledSelect
        value={currency}
        onChange={handleCurrencyChange}
        displayEmpty
        variant="outlined"
        inputProps={{ "aria-label": "Select Currency" }}
      >
        <MenuItem value="" disabled>
          <FaGlobe style={{ marginRight: 8, color: "#7F00FF" }} />
          Select Your Currency
        </MenuItem>
        <MenuItem value="usd">US Dollar</MenuItem>
        <MenuItem value="cfa">Francs CFA</MenuItem>
        <MenuItem value="gbp">Pounds Sterling</MenuItem>
      </StyledSelect>
      <StyledSelect
        value={category}
        onChange={handleCategoryChange}
        displayEmpty
        variant="outlined"
        inputProps={{ "aria-label": "Select Giving Category" }}
      >
        <MenuItem value="" disabled>
          <FaMoneyBill style={{ marginRight: 8, color: "#7F00FF" }} />
          Select a Giving Category
        </MenuItem>
        <MenuItem value="offering">General Offering</MenuItem>
        <MenuItem value="seed">Special Seed</MenuItem>
        <MenuItem value="outreach">Global Outreach</MenuItem>
      </StyledSelect>
      <AnimatedButton>Make Giving</AnimatedButton>
      <GiveFooter>
        <Typography>Thank you for your generosity! ❤️</Typography>
      </GiveFooter>
    </GiveContainer>
  );
}