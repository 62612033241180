import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import AddBlog from "./addBlog";
import AddTestimony from "./addTestimony";
import AddEvent from "./addEvent";
import AddAnnouncement from "./addAnnouncement";

interface TabPanelProps{
    children?:React.ReactNode;
    index:number;
    value:number;
}

const TabPanel: React.FC<TabPanelProps>=({children,index,value,...other})=>{
    return(
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        
        >{ value===index && (<Box sx={{p:3}}>
            <Typography>{children}</Typography>
        </Box>)}

        </div>
    )
}


const Publisher : React.FC=()=>{
    const [activeTab, setActiveTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number)=>{
        setActiveTab(newValue)
    }

    return(
        <Box sx={{width:'100%'}}>
            <Box sx={{borderBottom:1,borderColor:'divider'}}>
                <Tabs value={activeTab} onChange={handleChange} aria-label="content tabs">
                    <Tab label="Blogs" />
                    <Tab label="Testimonies" />
                    <Tab label="Events" />
                    <Tab label="Announcements" />
                </Tabs>
            </Box>

            <TabPanel value={activeTab} index={0}  >
                <AddBlog /></TabPanel>     
            <TabPanel value={activeTab} index={1}  >
                <AddTestimony /></TabPanel> 
            <TabPanel value={activeTab} index={2}  >
                <AddEvent /></TabPanel> 
            <TabPanel value={activeTab} index={3} >
                <AddAnnouncement /></TabPanel> 
            
             </Box>
    )
}
export default Publisher;