import { useEffect, useState } from "react";
import BlogList from "../../components/Blog/BlogList/BlogList";
import TopAuthor from "../../components/Blog/BlogList/TopAuthor";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import DynamicHero from "../components/Blog/Hero/Hero";
import TestimoniesData, {
  ToptestifiersData,
} from "../components/Blog/testimoniesList/Testimonies";
import { gettestimonies } from "../../redux/testimonyManager/testimonyService";

export const getStaticProps = async () => {
  return {
    props: { TestimoniesData },
  };
};
 interface BlogData{
  img:string,
  blogHeading:string,
  postedby : string,
  time:string,
  views:string,
  discreption: string
id: string
}
export default function TestimoniesPage() {
  const dispatch = useAppDispatch();

  const { testimonies } = useAppSelector((state) => state.testimonies); // Redux state for posts
  const [blogData, setBlogData] = useState<BlogData[]>([]); // State for converted Blog Data

  useEffect(()=>{
    dispatch(gettestimonies());
  },[dispatch]);

    // Convert post list to BlogData format
    useEffect(() => {
      if (testimonies.length) {
        const convertedData = testimonies.map((testimony , index) => ({
          id: testimony.testimonyId, // Generate ID (use index or post ID from backend)
          img: testimony.imageUrl, // Rotate images
          blogHeading: testimony.title || "Untitled Post",
          postedby: testimony.memberId || "Unknown Author",
          time: new Date(testimony.date).toLocaleDateString(),
          views: ``, // Example: count likes as views
          discreption: testimony.content, // Truncate content
        }));
        setBlogData(convertedData);
      }
    }, [testimonies]);
  return (
    <>
      <DynamicHero title="Testimonies" />
      <BlogList
        title="Latest Testimonies"
        title1=" Testimonies Category"
        title2="Recent Testimonies"
        title3="Related Testimonies"
        data={blogData}
        category1="Salvation"
        category2="Healing"
        category3="Deliversnce"
        category4="Financial Breakthrough"
        tag1="faith"
        tag2="prayer"
        tag3="salvation"
        tag4="prayer"
        tag5="Thanksgiving"
        recent1="Spiritual growth"
        recent2="Open doors for marriage"
        rel1="Fruit  of  the womb"
        rel2="community service"
      />
      <TopAuthor title="Top Testifiers of the week" data={ToptestifiersData} />
    </>
  );
}
