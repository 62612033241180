import { PastorsContainer, PastorsText, PastorsTitle } from "./pastors.styles";
import PastorsList from "./pastorsList";

export default function Pastors() {
  return (
    <PastorsContainer>
      <PastorsTitle>Meet Our Pastors</PastorsTitle>
      <PastorsText>
        Meet our dedicated pastors,passionate about spreading the gospel and
        serving our community. With years of experience and a deep love of God's
        people,they provide spiritual guidance and leadership to our church
        family.Learn more about their vision and ministry
      </PastorsText>
      <PastorsList />
    </PastorsContainer>
  );
}
