import { createTheme } from "@mui/material/styles";

// Extend BreakpointOverrides to include custom breakpoints
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // default breakpoints
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true; // custom breakpoints
    laptop: true;
    desktop: true;
  }
}

// Define shared color palette for both themes
export const Colors = {
  primary: "#1E3A8A", // Dark blue
  primaryDark: "#0B2447", // Darker blue
  primaryLight: "#3B82F6", // Blue
  secondary: "#EF4444", // Red
  secondaryDark: "#B91C1C", // Dark red
  secondaryLight: "#FCA5A5", // Light red
  white: "#FFFFFF", // White
  black: "#000000", // Black
  greyText: "#6B7280", // Neutral grey for text
  greyBackground: "#F3F4F6", // Light grey for backgrounds
  darkBackground: "#111827", // Very dark grey for dark mode background
  darkSurface: "#1F2937", // Surface color for dark mode
  textPrimaryLight: "#1F2937", // Dark text for light mode
  textPrimaryDark: "#E5E7EB", // Light text for dark mode
  textSecondaryLight: "#4B5563", // Secondary text for light mode
  textSecondaryDark: "#9CA3AF", // Secondary text for dark mode
};

// Light theme
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: Colors.primary,
      dark: Colors.primaryDark,
      light: Colors.primaryLight,
    },
    secondary: {
      main: Colors.secondary,
      dark: Colors.secondaryDark,
      light: Colors.secondaryLight,
    },
    text: {
      primary: Colors.textPrimaryLight,
      secondary: Colors.textSecondaryLight,
    },
    background: {
      default: Colors.greyBackground, // Light grey background
      paper: Colors.white, // White surfaces
    },
    grey: {
      100: Colors.greyBackground, // For light grey elements
      500: Colors.greyText, // Neutral grey
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      tablet: 769, // Custom breakpoint
      laptop: 1024, // Custom breakpoint
      desktop: 1440, // Custom breakpoint
    },
  },
});

// Dark theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: Colors.primaryLight, // Use lighter blue for better visibility
      dark: Colors.primaryDark,
      light: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
      dark: Colors.secondaryDark,
      light: Colors.secondaryLight,
    },
    text: {
      primary: Colors.textPrimaryDark,
      secondary: Colors.textSecondaryDark,
    },
    background: {
      default: Colors.darkBackground, // Very dark grey background
      paper: Colors.darkSurface, // Slightly lighter surface color
    },
    grey: {
      100: Colors.darkSurface, // For dark grey elements
      500: Colors.greyText, // Neutral grey
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      tablet: 769, // Custom breakpoint
      laptop: 1024, // Custom breakpoint
      desktop: 1440, // Custom breakpoint
    },
  },
});

export default lightTheme;