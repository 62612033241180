import {
  GivingButton,
  GivingContainer,
  GivingContainercol,
  GivingText,
  GivingTitle,
} from "./giving.styles";

export default function Giving() {
  return (
    <GivingContainer>
      <GivingContainercol>
        <GivingTitle>Online Giving</GivingTitle>
        <GivingText>
          Partner with us in ministry through online giving. Your financial
          support enables us to share the Good News, care for those in need, and
          build a community of faith.Give securely oline today
        </GivingText>
        <GivingButton>Make Donation</GivingButton>
      </GivingContainercol>
    </GivingContainer>
  );
}
