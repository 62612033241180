import { Box, Button, styled, Typography } from "@mui/material";
import img1 from "../../../../assets/images/rev.jpg";

export const ProphetContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "2rem auto ",
  backgroundColor: theme.palette.background.default,
}));

export const ProphetCard = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  display: "flex",
  flexDirection: "row",
  width: "80%",
  maxWidth: "1200px",
  height: "70vh",
  margin: "8% auto",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
    marginLeft: "3%",
    width: "90%",
  },
}));

export const ProphetCardImage = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  width: "50%",
  height: "100%",
  backgroundSize: "cover",
  backgroundImage: `url(${img1})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "50vh",
  },
}));

export const ProphetTitle = styled(Typography)(({ theme }) => ({
  margin: "1rem 0",
  fontSize: "2.5rem",
  fontWeight: "bold",
  fontFamily: "serif",
  textAlign: "center",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.8rem",
  },
}));

export const ProphetText = styled(Typography)(({ theme }) => ({
  margin: "0.5rem 0",
  fontSize: "1rem",
  fontWeight: "500",
  fontFamily: "serif",
  textAlign: "start",
  color: theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
    textAlign: "center",
  },
}));

export const ProphetCol = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginTop: "1rem",
  },
}));

export const ProphetButtontwo = styled(Button)(({ theme }) => ({
  marginTop: "1.5rem",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: "1rem",
  fontWeight: "bold",
  borderRadius: "25px",
  padding: "0.5rem 1.5rem",
  textTransform: "none",
  transition: "background-color 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    fontSize: "0.9rem",
  },
}));