import { Grid } from "@mui/material";
import Counter from "./counter";
import { CounterContainer, CounterContainerOverlay } from "./counter.styles";

const CounterList = () => {
  return (
    <CounterContainer>
      <CounterContainerOverlay>
        <Grid container spacing={2}>
          <Counter title="Pastors" value={100} />
          <Counter title="Administrators" value={50} />
          <Counter title="Members" value={1700} />
          <Counter title="Partners" value={700} />
        </Grid>
      </CounterContainerOverlay>
    </CounterContainer>
  );
};
export default CounterList;
