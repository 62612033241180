import React from "react";
import {
  FaArrowAltCircleRight,
} from "react-icons/fa";
import {
  ProphetButtontwo,
  ProphetCard,
  ProphetCardImage,
  ProphetCol,
  ProphetContainer,
  ProphetText,
  ProphetTitle,
} from "./prophet.styles";

export default function Prophet() {
  return (
    <ProphetContainer>
      <ProphetCard>
        <ProphetCardImage />
        <ProphetCol>
          <ProphetTitle>About The Prophet</ProphetTitle>
          <ProphetText>
            Prophet Tembeck Tiku Aldrian is a man called by God to be his
            representative on earth. He is an agent of God gifted by God to
            declare His counsel, to be an envoy, a watchman, and a bearer of
            royal authority. Through the grace of God bestowed on him, many are
            delivered from the shackles of captivity through deliverance,
            healing, and by prophecy.
          </ProphetText>
          <ProphetCol>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{ marginRight: "10px", color: "#1976d2" }}
              />
              A man after God's heart
            </ProphetText>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{ marginRight: "10px", color: "#1976d2" }}
              />
              The Voice of Empowerment
            </ProphetText>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{ marginRight: "10px", color: "#1976d2" }}
              />
              A Kingdom Militant
            </ProphetText>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{ marginRight: "10px", color: "#1976d2" }}
              />
              A Prophet from God
            </ProphetText>
            <ProphetButtontwo>Get Started</ProphetButtontwo>
          </ProphetCol>
        </ProphetCol>
      </ProphetCard>
    </ProphetContainer>
  );
}