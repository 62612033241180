import { Post } from "../../utils/types/postManagement";
import { db } from "../firebase"; // Make sure you configure Firebase properly
import {
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";


// Fetch all posts from Firestore
export const fetchPostsFromFirestore = async (): Promise<Post[]> => {
  const querySnapshot = await getDocs(collection(db, "posts"));

  return querySnapshot.docs.map((doc) => Post.fromFirestore(doc));
};

// Add a new post to Firestore
export const addPostToFirestore = async (post: Post): Promise<void> => {
  const docRef = doc(db, "posts", post.postid);
  await setDoc(docRef, post.toFirestore());
};

// Update an existing post in Firestore
export const updatePostInFirestore = async (id: string, post: Post): Promise<void> => {
  const docRef = doc(db, "posts", id);
  await updateDoc(docRef, post.toFirestore());
};

// Delete a post from Firestore
export const deletePostFromFirestore = async (id: string): Promise<void> => {
  const docRef = doc(db, "posts", id);
  await deleteDoc(docRef);
};