import { Box, Button, styled, Typography } from "@mui/material";
import img1 from '../../../assets/images/hi.jpg'
export const IntroContainer = styled(Box)(({theme})=>({
    display:'flex',
    backgroundColor:theme.palette.background.default,
    flexDirection:'row',marginTop:'-20vh',
    minWidth:'50%',width:'80%',maxWidth:'100%',minHeight:'40vh',marginLeft:'10%',marginBottom:'5%',
    [theme.breakpoints.down('md')]: {
        display:'flex',
        flexDirection:'column',marginTop:'-30vh'
    }
}))

export const IntroCol = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column'
}))

export const IntroTitle = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const IntroText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: theme.palette.text.primary,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));

export const IntroImage = styled(Box)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    height: '45vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '10px',
    minWidth: '50%',
    borderBottomLeftRadius: '10px',
    maxWidth: '100%',
    width: '50%',backgroundImage:`url(${img1})`,
    [theme.breakpoints.down('md')]: {
        height: '45vh', width:'100%',borderRadius:'10px'// Adjust height for smaller screens
    },
}));

export const IntroButton = styled(Button)(({ theme }) => ({
    color: 'white',marginTop:'1%',marginBottom:'6%',
    fontSize: '1rem',
    height: '15%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',fontWeight:'bold',
    maxWidth: '50%', 
    paddingLeft: '1%',marginLeft:'35%',background:'linear-gradient(to bottom,#1E3A8A,white)',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'25%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));

