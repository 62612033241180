import AboutReview from "../aboutReview/aboutReview";
import AboutRow from "../aboutRow/aboutRow";
import { AboutRowLine } from "../aboutRow/aboutRow.styles";
import Explore from "../explore/explore";
import Prophet from "../prophet/prophet";
import Revolution from "../revolution/revolution";
import { AboutRevContainer, AboutRevLine, AboutRevRow, AboutRevTitle, MissionContainer } from "./aboutRev.styles";

export default function AboutRev(){

    return(<AboutRevContainer>
<Prophet />
<AboutRow />
<AboutRowLine />

<Revolution /> 
<AboutReview />
<Explore />

    </AboutRevContainer>)
} 