import { Box, Button, Card, styled, Typography } from "@mui/material";

export const UpcomingEventsContainer= styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',margin:'0 auto'
}))

export const UpcomingEventsTitle= styled(Typography)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const UpcomingEventsText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color:theme.palette.text.primary,
    margin:'auto',
    textAlign: 'center',
    width:'80%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));

export const UpcomingEventsRow= styled(Box)(({theme})=>({
    display:'flex',flexDirection:'row', minWidth:'50%',width:'80%',maxWidth:'100%',minHeight:'40vh',marginLeft:'10%',marginBottom:'5%'
, [theme.breakpoints.down('md')]: {
    display:'flex',flexDirection:'column',
}
}))

export const CardContainer = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4%',
    marginLeft: '7%',
    marginBottom: '5%',width:'90%',minWidth:'50%',maxWidth:'100%',
    [theme.breakpoints.down('md')]: {
        marginLeft: '5%',
       // Adjust margin for smaller screens
    },
  }));
  
  export const CardRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',   
    marginTop: '3%',
    marginLeft: '0%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack cards vertically on smaller screens
        width: '100%',
        marginLeft: '2%',
    },
  }));
  
  export const CardContent = styled(Card)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'creamWhite',
    borderRadius: '10px',
    minWidth: '20%',
    maxWidth: '30%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
      maxWidth: '100%',
      width: '100%',marginTop:'10%'
    },
  }));
  
  export const CardContentText = styled(Typography)(({ theme }) => ({
    fontSize:'0.9rem',
    [theme.breakpoints.down('md')]: {
        width: '100%', // Make cards full-width on smaller screens
        margin: '10px 0',fontSize:'0.8rem'
    },
  }));
  
  export const CardImage = styled('img')(({ theme }) => ({
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
   // backgroundColor: theme.palette.primary.main,
    color: 'white',
   
    height: '23vh',
    width: '100%',
    marginLeft: '0%',
    marginTop: '2%',marginBottom:'2%',
    zIndex: '1','&:hover':{transform:'scale(1.1)',},
    [theme.breakpoints.down('md')]: {
        height: '20vh', // Adjust height for smaller screens
    },
  }));
  
  export const CardTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'start',

    [theme.breakpoints.down('md')]: {
        fontSize: '1rem', // Adjust font size for smaller screens
    },
  }));
  
  export const CardHeaderTitle = styled(Typography)(({ theme }) => ({
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'serif',
    marginTop: '4%',animation:'fadeIn 2s', 
    '@keyframes fadeIn':{
        '0%':{
            opacity:0
        }
        ,   '100%':{
            opacity:1
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '2rem', // Adjust font size for smaller screens
    },
  }));

  export const UpcomingButton = styled(Button)(({ theme }) => ({
    color: 'white',marginTop:'1%',marginBottom:'6%',
    fontSize: '1rem',
    height: '15%',
    border: '1px solid white',
    width: '50%',
    minWidth: '10%',fontWeight:'bold',
    maxWidth: '50%', 
    paddingLeft: '1%',marginLeft:'3%',background:'linear-gradient(to bottom,#1E3A8A,white)',
    [theme.breakpoints.down('md')]: {
        width: '45%', // Make buttons full-width on smaller screens
        marginLeft:'25%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));
