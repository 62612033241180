import img1 from "../../../assets/images/hi.jpg";
import img2 from "../../../assets/images/7.jpg";
import img3 from "../../../assets/images/3.jpg";
import img4 from "../../../assets/images/4.jpg";
import img5 from "../../../assets/images/8.jpg";
import img6 from "../../../assets/images/6.jpg";
import img7 from "../../../assets/images/2.jpg";
import img8 from "../../../assets/images/10.jpg";
import React, { useState } from "react";
import { image } from "html2canvas/dist/types/css/types/image";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { PastorCardImage } from "../pastors/pastors.styles";
import { GalleryImage, GalleryRow } from "./gallery.styles";
interface Galleryimage {
  id: number;
  image: string;
  category: string;
}

const imagesData: Galleryimage[] = [
  { id: 1, image: `url(${img1})`, category: "sermons" },
  { id: 2, image: `url(${img2})`, category: "community" },
  { id: 3, image: `url(${img3})`, category: "others" },
  { id: 4, image: `url(${img4})`, category: "sermons" },
  { id: 1, image: `url(${img5})`, category: "sermons" },
  { id: 2, image: `url(${img6})`, category: "community" },
  { id: 3, image: `url(${img7})`, category: "others" },
  { id: 4, image: `url(${img8})`, category: "sermons" },
];

const categories = ["all", "sermons", "community", "others"];

const Gallerycomp: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>("all");

  const handleCategoryChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setSelectedCategory(newValue);
  };

  const filteredImages = imagesData.filter((image) =>
    selectedCategory === "all" ? true : image.category === selectedCategory
  );
  return (
    <Container>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={selectedCategory}
        onChange={handleCategoryChange}
        sx={{
          backgroundColor: "rgba(138,43,226,0.2)",
          alignContent: "center",
          paddingLeft: "30%",
          marginTop: "2%",
          fontWeight: "bolder",
          "@media(max-width:768px)": { paddingLeft: "10%" },
          "&:hover": { color: "white" },
        }}
      >
        {categories.map((category, index) => (
          <Tab
            key={category}
            label={category.charAt(0).toUpperCase() + category.slice(1)}
            value={category}
          />
        ))}
      </Tabs>
      <Grid container spacing={2} style={{ marginTop: "16px" }}>
        {filteredImages.map((image, index) => (
          <Grid item lg={4} xs={12} sm={6} md={4} key={index}>
            <GalleryImage
              style={{ backgroundImage: image.image }}
            ></GalleryImage>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default Gallerycomp;
