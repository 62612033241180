import { Box, styled, Typography } from "@mui/material";
import img1 from '../../../assets/images/2.jpg'
export const CounterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(0,0,0,0.2)',
    backgroundImage: `url(${img1})`,
    minHeight: '29vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', // Adjust background size for better responsiveness
    backgroundPositionY: 'center',
    backgroundPositionX: 'right',
    width: '100%',
    minWidth: '70%',
    maxWidth: '100%',

    [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '20vh', // Adjust height for smaller screens
        maxHeight: '30vh',
        height: '30vh',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center' // Center the background image on smaller screens
    }
}));

export const CounterContainerOverlay = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    borderBottomRightRadius: '20px',
    borderBottomLeftRadius: '20px',
    backgroundColor: 'rgba(138,43,226,0.4)',
    minHeight: '29vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'right',  width: '100%',
    minWidth: '30%',paddingLeft:'3%',
    maxWidth: '100%',alignContent:'center',justifyContent:'space-evenly',

    [theme.breakpoints.down('md')]: {
        width: '100%',
        minWidth: '30%',
        maxWidth: '100%',
        marginLeft: '0%',
        minHeight: '20vh', // Adjust height for smaller screens
        maxHeight: '50vh',
        height: '30vh',display: 'flex',
        flexDirection: 'column',
        backgroundSize: 'cover',
        backgroundPositionY: 'center',
        backgroundPositionX: 'center',// Center the background image on smaller screens
    }
}));
export const CounterColContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',padding:'5%',alignItems:'center',justifyContent:'center',
    


}));
export const Counternumber = styled(Typography)(({ theme }) => ({
    fontSize:'3rem',color:'white',fontWeight:'bold'
    ,[theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }


}));
export const Countertitle = styled(Typography)(({ theme }) => ({
    fontSize:'2rem',color:'white',fontWeight:'bold'
    , [theme.breakpoints.down('md')]: {
        fontSize: '0.9rem',
    }


}));