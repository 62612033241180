import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPostsFromFirestore,
  addPostToFirestore,
  updatePostInFirestore,
  deletePostFromFirestore,
} from "./postService";
import { Post } from "../../utils/types/postManagement";


// Define the state structure
interface PostState {
  posts: Post[];
  isLoading: boolean;
  isSuccess: boolean;
  hasError: boolean;
  error: string | null;
  successMessage: string | null;
}

const initialState: PostState = {
  posts: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  error: null,
  successMessage: null,
};

// Async Thunks

// Fetch all posts
export const fetchPosts = createAsyncThunk<Post[], void, { rejectValue: string }>(
  "posts/fetchPosts",
  async (_, { rejectWithValue }) => {
    try {
      return await fetchPostsFromFirestore();
    } catch (error) {
      return rejectWithValue("Failed to fetch posts");
    }
  }
);

// Add a new post
export const addPost = createAsyncThunk<void, Post, { rejectValue: string }>(
  "posts/addPost",
  async (post, { rejectWithValue }) => {
    try {
      await addPostToFirestore(post);
    } catch (error) {
      return rejectWithValue("Failed to add post");
    }
  }
);

// Update a post
export const updatePost = createAsyncThunk<
  void,
  { id: string; post: Post },
  { rejectValue: string }
>("posts/updatePost", async ({ id, post }, { rejectWithValue }) => {
  try {
    await updatePostInFirestore(id, post);
  } catch (error) {
    return rejectWithValue("Failed to update post");
  }
});

// Delete a post
export const deletePost = createAsyncThunk<void, string, { rejectValue: string }>(
  "posts/deletePost",
  async (id, { rejectWithValue }) => {
    try {
      await deletePostFromFirestore(id);
    } catch (error) {
      return rejectWithValue("Failed to delete post");
    }
  }
);

// Redux Slice
const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Posts
      .addCase(fetchPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.posts = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to fetch posts";
      })

      // Add Post
      .addCase(addPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPost.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Post added successfully!";
      })
      .addCase(addPost.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to add post";
      })

      // Update Post
      .addCase(updatePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePost.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Post updated successfully!";
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to update post";
      })

      // Delete Post
      .addCase(deletePost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePost.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.successMessage = "Post deleted successfully!";
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload || "Failed to delete post";
      });
  },
});

// Export actions and reducer
export const { resetState } = postSlice.actions;
export default postSlice.reducer;