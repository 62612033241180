// Footer.jsx
import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  InputBase,
  IconButton,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { newsLetter } from "../../../redux/newsLetterSubscription/newsLetterService";
import { useAppDispatch } from "../../../redux/store";
import Map from "../Map";
import { Link } from "react-router-dom";

const FooterContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.background.paper})`,
  color: theme.palette.common.white,
  padding: "60px 30px",
  position: "relative",
  zIndex: 10,
  [theme.breakpoints.down("sm")]: {
    padding: "40px 20px",
  },
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  marginBottom: "40px",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const FooterHeading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "700",
  color: theme.palette.common.white,
  marginBottom: "20px",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.grey[300],
  marginBottom: "10px",
  cursor: "pointer",
  transition: "color 0.3s",
display:"block",
  "&:hover": {
    color: theme.palette.common.white,
  },
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.light,
  marginRight: "10px",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const NewsletterContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
}));

const NewsletterInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  padding: "10px 15px",
  borderRadius: "30px 0 0 30px",
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  fontSize: "14px",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: "0 30px 30px 0",
  padding: "10px 20px",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const FooterBottom = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: "20px 0",
  borderTop: `1px solid ${theme.palette.grey[700]}`,
  marginTop: "40px",
  "& span": {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}));

const Footer = () => {
  const [email, setEmail] = useState({ email: "" });
const theme = useTheme();
const [isValidEmail, setIsValidEmail] = useState(true);
const [isSubmitting, setSubmitting] = useState(false);
const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
  const inputEmail = e.target.value;
  setEmail({ email: inputEmail });
  
  

  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  setIsValidEmail(emailRegex.test(inputEmail));
  };
  const dispatch = useAppDispatch();
  const handleSubmit = () => {
    if (!isValidEmail) {
    return null;
    } else {
    setSubmitting(true);
    dispatch(newsLetter(email));
    setEmail({ email: "" });
    }
    };

  return (
    <FooterContainer>
      <FooterGrid container spacing={4}>
        {/* About Section */}
        <Grid item xs={12} sm={6} md={3}>
          <FooterHeading>Discover</FooterHeading>
          <FooterLink to={'/church'}>Empowerment Church</FooterLink>
          <FooterLink to={'/tv'}>Empowerment TV</FooterLink>
          <FooterLink to={'/foundation'}>Empowerment Foundation</FooterLink>
          <FooterLink to={'/ebnps'}>Empowerment School</FooterLink>
          <FooterLink to={'/jesuschristmedia'}>Jesus Christ Media</FooterLink>
          <FooterLink to={'/schoolofministry'}>School of Ministry</FooterLink>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} sm={6} md={3}>
          <FooterHeading>Quick Links</FooterHeading>
          <FooterLink to={'/about'}>About US</FooterLink>
          <FooterLink to={'/contact'}>Contact Us</FooterLink>
          <FooterLink to={'/faq'}>FAQs</FooterLink>
          <FooterLink to={'/event'}>Events</FooterLink>
          <FooterLink to={'/download'}>Download App</FooterLink>
          <FooterLink to={'/termsofservice'}>Terms of Service</FooterLink>
          <FooterLink to={'/privacy'}>Privacy Policy</FooterLink>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} sm={6} md={3}>
          <FooterHeading>Follow Us</FooterHeading>
          <Box>
            <SocialIcon>
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon>
              <InstagramIcon />
            </SocialIcon> 
            <SocialIcon>
              <TwitterIcon />
            </SocialIcon>
            <SocialIcon>
              <LinkedInIcon />
            </SocialIcon>
            <SocialIcon>
              <WhatsAppIcon />
            </SocialIcon>
          </Box><Map />
        </Grid>

        {/* Newsletter Section */}
        <Grid item xs={12} sm={6} md={3}>
          <FooterHeading>Newsletter</FooterHeading>
          <Typography variant="body2" color="inherit">
            Subscribe to get the latest updates and offers.
          </Typography>
          <NewsletterContainer>
            <NewsletterInput
          sx={{
            "&:after": { display: "none" },
            "&:before": { display: "none" },
           // height: "28px",
            borderColor: isValidEmail ? "" : "red",
          }}
          placeholder="email@gmail.com"
          type="email"
          value={email.email}
          onChange={handleInputChange}
            />
            <SubmitButton
              onClick={handleSubmit}
              endIcon={<SendIcon />}
              disabled={!isValidEmail}
            >
              Subscribe
            </SubmitButton>
          </NewsletterContainer>
        </Grid>
      </FooterGrid>

      {/* Footer Bottom */}
      <FooterBottom>
        <Typography variant="body2" color="inherit">
          © 2024 The Empowerment Revolution. All Rights Reserved. Designed by{" "}
       <Link to={'tel:237652718184'}>   <span>Infinite Web Revolution</span>.</Link>
        </Typography>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
