import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { EventsState } from "../../utils/types/eventsManager";
import { addEvent, deleteEvent, getEvents, updateEvent } from "./eventService";


// Initial state for the events slice
const initialState: EventsState = {
  events: [],
  isLoading: false,
  isSuccess: false,
  hasError: false,
  hasWarning: false,
  error: null,
  successMessage: null,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    // Utility to refresh/reset the state
    refreshState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasError = false;
      state.hasWarning = false;
      state.error = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Events
      .addCase(getEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.events = action.payload || [];
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to fetch events";
      })

      // Add Event
      .addCase(addEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.events.push(action.payload);
        state.successMessage = "Event added successfully!";
        toast.success("Event added successfully!");
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to add event";
      })

      // Update Event
      .addCase(updateEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const updatedEvent = action.payload;
        const index = state.events.findIndex((event) => event.eventId === updatedEvent.eventId);
        if (index !== -1) {
          state.events[index] = updatedEvent;
        }
        state.successMessage = "Event updated successfully!";
        toast.success("Event updated successfully!");
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to update event";
      })

      // Delete Event
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.events = state.events.filter((event) => event.eventId !== action.payload.eventId);
        state.successMessage = "Event deleted successfully!";
        toast.success("Event deleted successfully!");
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.error = action.payload?.join(", ") || "Failed to delete event";
      });
  },
});

// Export actions and reducer
export const { refreshState } = eventsSlice.actions;
export default eventsSlice.reducer;