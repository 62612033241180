import BlogList from "../../components/Blog/BlogList/BlogList";
import TopAuthor from "../../components/Blog/BlogList/TopAuthor";
import AnnouncentData, { TopannData } from "../components/announcement";
import DynamicHero from "../components/Blog/Hero/Hero";
import { ToptestifiersData } from "../components/Blog/testimoniesList/Testimonies";

export default function AnnouncementPage(){
    return(
        <>
        <DynamicHero title="Announcements" />
        <BlogList title="Latest Announcements" title1=" Announcements Category" title2="Recent Announcement" 
        title3="Related Announcement" data={AnnouncentData} category1="Ministry" category2="Department" category3="Sermons" category4="Financial Breakthrough" tag1="faith" tag2="prayer" tag3="salvation" tag4="prayer" tag5="Thanksgiving" 
        recent1="Spiritual growth" recent2="Live Sermons" rel1="Stewardship" rel2="community service" />
       <TopAuthor title="Top Announcements of the week" data={TopannData}       />
        </>
    )
}