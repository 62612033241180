import React from "react";
import {
  FaHandHoldingHeart,
  FaPrayingHands,
  FaSchool,
} from "react-icons/fa";
import {
  ExploreCard,
  ExploreCardRow,
  ExploreCardTitle,
  ExploreCol,
  ExploreContainer,
  ExploreImage,
  ExploreLeft,
  ExploreText,
  ExploreTitle,
} from "./explore.styles";

export default function Explore() {
  return (
    <ExploreContainer>
      <ExploreLeft>
        <ExploreTitle>The Empowerment Revolution</ExploreTitle>
        <ExploreText>
          Train Kingdom Militants in the <br />
          {<strong>Arena of Excellence</strong>}
        </ExploreText>
        <ExploreCardRow>
          <ExploreCard>
            <FaPrayingHands size={20} style={{ color: "#1976d2" }} />
            <ExploreCol>
              <ExploreCardTitle>Ignite Your Potential</ExploreCardTitle>
            </ExploreCol>
          </ExploreCard>
          <ExploreCard>
            <FaSchool size={20} style={{ color: "#1976d2" }} />
            <ExploreCol>
              <ExploreCardTitle>Unleash Your Greatness</ExploreCardTitle>
            </ExploreCol>
          </ExploreCard>
          <ExploreCard>
            <FaHandHoldingHeart size={20} style={{ color: "#1976d2" }} />
            <ExploreCol>
              <ExploreCardTitle>Rise to Your Destiny</ExploreCardTitle>
            </ExploreCol>
          </ExploreCard>
        </ExploreCardRow>
      </ExploreLeft>
      <ExploreImage />
    </ExploreContainer>
  );
}