import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { FaChurch, FaHome, FaSchool, FaBullhorn, FaNewspaper, FaHandshake, FaLaptop } from "react-icons/fa";
const cardsData = [
  {
    id: 1,
    icon: <FaBullhorn />,
    title: "Events",
    description:
      "Immerse yourself in a world of spiritual renewal and empowerment. Our dynamic events feature inspiring messages, powerful worship, and opportunities to connect with like-minded believers.",
    bgColor: "linear-gradient(135deg, #2575fc 0%, #6a11cb 100%)", // Blue
  },
  {
    id: 2,
    icon: <FaHandshake />,
    title: "Partnership",
    description:
      "Join us in building God's Kingdom together. Partner with us to support our mission and make a lasting impact on the lives of others.",
    bgColor: "linear-gradient(135deg, #ff512f 0%, #dd2476 100%)", // Red-Purple
  },
  {
    id: 3,
    icon: <FaSchool />,
    title: "Education",
    description:
      "Invest in the future of our children by supporting our educational initiatives. Your generous gift will help provide quality Christian education for the next generation.",
    bgColor: "linear-gradient(135deg, #434343 0%, #000000 100%)", // Black (Dark Gradient)
  },
  {
    id: 4,
    icon: <FaChurch />,
    title: "Our Mission",
    description:
      "Discover our mission to spread the love of Christ and transform lives. Learn about our core values and how we're making a difference in our community and beyond.",
    bgColor: "linear-gradient(135deg, #5f2c82 0%, #49a09d 100%)", // Purple-Blue
  },
  {
    id: 5,
    icon: <FaNewspaper />,
    title: "Ministry News",
    description:
      "Stay connected with the latest news, updates, and inspiring stories from our ministry. Be the first to know about upcoming events, mission trips, and outreach programs.",
    bgColor: "linear-gradient(135deg, #ff4b2b 0%, #ff416c 100%)", // Red
  },
  {
    id: 6,
    icon: <FaLaptop />,
    title: "Technology",
    description:
      "Experience the power of technology to enhance your spiritual journey. Explore our digital resources, online services, and mobile apps designed to connect you with God and His people.",
    bgColor: "linear-gradient(135deg, #4b79a1 0%, #283e51 100%)", // Blue-Black
  },
];
export default function AdvancedCards() {
  

  return (
    <CardsContainer>
      {cardsData.map((card) => (
        <StyledCard key={card.id} color={card.bgColor}>
          <CardIcon>{card.icon}</CardIcon>
          <CardContent>
            <CardTitle>{card.title}</CardTitle>
            <CardText>{card.description}</CardText>
            <CallToAction>
              <Button variant="contained" color="primary">
                Learn More
              </Button>
            </CallToAction>
          </CardContent>
        </StyledCard>
      ))}
    </CardsContainer>
  );
}

// Styled Components
const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "2rem",
  padding: "2rem",
  backgroundColor: theme.palette.background.default,
}));

const StyledCard = styled(Card)(({ color, theme }) => ({
  position: "relative",
  width: "300px",
  borderRadius: "20px",
  overflow: "hidden",
  background: color,
  color: "white",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 12px 30px rgba(0, 0, 0, 0.3)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.2)",
    zIndex: 0,
    pointerEvents: "none",
  },
  [theme.breakpoints.down("sm")]: {
    width: "95%",
  },
}));

const CardIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "80px",
  height: "80px",
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  color: "white",
  fontSize: "2rem",
  borderRadius: "50%",
  margin: "1.5rem auto 0",
  zIndex: 1,
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  textAlign: "center",
  marginTop: "1rem",
  zIndex: 1,
}));

const CardText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  textAlign: "center",
  marginTop: "1rem",
  padding: "0 1rem",
  zIndex: 1,
}));

const CallToAction = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "1.5rem",
  zIndex: 1,
}));