import { Box, Button, Card, styled, Typography } from "@mui/material";

export const JoinContainer= styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',margin:'0 auto'
}))

export const JoinTitle= styled(Typography)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const JoinText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color:theme.palette.text.primary,
    margin:'auto',
    textAlign: 'center',
    width:'80%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));

export const JoinButton = styled(Button)(({ theme }) => ({
    color: 'white',marginTop:'3%',marginBottom:'6%',
    fontSize: '1rem',
    height: '15%',
    border: '1px solid white',
    width: '30%',
    minWidth: '10%',fontWeight:'bold',
    maxWidth: '30%', 
    paddingLeft: '1%',marginLeft:'35%',background:'linear-gradient(to bottom,#1E3A8A,white)',
    [theme.breakpoints.down('md')]: {
        width: '100%', maxWidth:'100%',// Make buttons full-width on smaller screens
        marginLeft:'2%',height:'6vh',fontSize:'0.8rem', paddingLeft:'1.5%',paddingTop:'1.5%',paddingRight:'1.5%',paddingBottom:'3%'
    }
}));