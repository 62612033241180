import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { CardImage } from "../upcomingevents/upcomingEvents.styles";
import { PostMedia } from "../../../utils/types/postManagement";

interface BlogProps {
  postmedia: PostMedia[];
  date: string;
  title: string;
  description: string;
  likes: number;
  comments: number;
}

const Blog: React.FC<BlogProps> = ({
  postmedia,
  date,
  title,
  description,
  likes,
  comments,
}) => {
  return (
    <Card sx={{ maxWidth: 345, position: "relative" }}>
      <CardActionArea style={{ height: "300px" }}>
        {postmedia !== null &&
          postmedia.map(
            (media, index) =>
              media.type === "image" && (
                <CardMedia>
                  <CardImage>
                    <img src={media.url} alt={media.name} key={index} />
                  </CardImage>
                </CardMedia>
              )
          )}

        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 10,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0,0,0,0.5)",
            color: "white",
            opacity: 0,
            transition: "opacity 0.3s",
            "&: hover": { opacity: 1 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "",
          }}
        >
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="inherit">
            {description}
          </Typography>
          <Typography variant="body2" color="inherit">
            Likes :{likes} Comments:{comments}
          </Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Read More
          </Button>
        </Box>
        <CardContent>
          <Typography
            variant="body2"
            color="text.primary"
            justifyContent={"flex-start"}
          >
            {date}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Blog;
