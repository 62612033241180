import React from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography, Checkbox, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

// Import styled components
import {
  AsideGrid,
  AsideGridHeading,
  AsideGridPost,
  AsideGridText,
  BlogDetail,
  BlogDetailItems,
  BlogImage,
  BlogItemWrapper,
  BlogItembg,
  BlogItems,
  BlogText,
  Chip,
  ChipWrapper,
  InputField,
  InputSearch,
  InputSearchBox,
  InputSearchWrapper,
} from "./BlogList.styles";

import magnifier from "../../../assets/blog/magnifier.png";

// Define props
interface BlogProps {
  title: string;
  title1: string;
  title2: string;
  title3: string;
  data: Blog[];
  showDetails?: boolean;
  category1: string;
  category2: string;
  category3: string;
  category4: string;
  tag1: string;
  tag2: string;
  tag3: string;
  tag4: string;
  tag5: string;
  recent1: string;
  recent2: string;
  rel1: string;
  rel2: string;
}

interface Blog {
  img: string;
  blogHeading: string;
  postedby: string;
  time: string;
  views: string;
  discreption: string;
  id: string;
}

const BlogList: React.FC<BlogProps> = ({
  title,
  title1,
  title2,
  title3,
  data,
  showDetails = true,
  recent1,
  recent2,
  rel1,
  rel2,
  tag1,
  tag2,
  tag3,
  tag4,
  tag5,
  category1,
  category2,
  category3,
  category4,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: "0 1rem",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Main Blog Section */}
      <Grid item xs={12} md={9}>
        {/* Search Box */}
        <InputSearchWrapper>
          <InputSearch>
            <InputSearchBox>
              <img src={magnifier} alt="magnifier" />
              <InputField 
                placeholder="Search..."
                sx={{
                  "&:after, &:before": { display: "none" },
                }}
              />
              <Button
                sx={{
                  borderRadius: "0px 7px 7px 0px",
                  height: "50px",
                  border: "1px solid #1573AE",
                  textTransform: "none",
                  padding: "0 20px",
                }}
                disableRipple
                variant="contained"
              >
                Subscribe
              </Button>
            </InputSearchBox>
          </InputSearch>
        </InputSearchWrapper>

        {/* Blog Items */}
        <BlogItems>
          <Typography variant="h1">{title}</Typography>
          <BlogItemWrapper container spacing={3}>
            {data.map((blog, index) => (
              <Link to={`/blogs/${blog.id}`} key={index}>
                <BlogItembg>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={3}>
                      <BlogImage>
                        <img
                          src={blog.img} 
                          alt={blog.blogHeading}
                          style={{ backgroundSize:"cover",
                            width: "100px",
                            height: "100px",
                            borderRadius: "8px",
                          }}
                        />
                      </BlogImage>
                    </Grid>
                    <BlogText item xs={9}>
                      <Typography variant="h2">{blog.blogHeading}</Typography>
                      <BlogDetail>
                        <BlogDetailItems color={theme.palette.info.dark}>
                          <PersonIcon sx={{ fontSize: "14px" }} />
                          <Typography variant="body1">
                            {blog.postedby}
                          </Typography>
                        </BlogDetailItems>
                        <BlogDetailItems color={theme.palette.info.dark}>
                          <WatchLaterIcon sx={{ fontSize: "14px" }} />
                          <Typography variant="body1">{blog.time}</Typography>
                        </BlogDetailItems>
                        <BlogDetailItems color={theme.palette.info.dark}>
                          <PeopleAltIcon sx={{ fontSize: "14px" }} />
                          <Typography variant="body1">{blog.views}</Typography>
                        </BlogDetailItems>
                      </BlogDetail>
                      <Typography variant="body1" style={{color:theme.palette.text.primary}}>
                        {blog.discreption}
                      </Typography>
                    </BlogText>
                  </Grid>
                </BlogItembg>
              </Link>
            ))}
          </BlogItemWrapper>
        </BlogItems>
      </Grid>

      {/* Sidebar Section */}
      <Grid item xs={12} md={3}>
        <AsideGrid container>
          {/* Recent Posts */}
          <AsideGridHeading item xs={12}>
            <Typography variant="h2" style={{color:theme.palette.text.primary}}>{title1}</Typography>
          </AsideGridHeading>
          {[recent1, recent2].map((post, index) => (
            <AsideGridText  color={theme.palette.text.primary} key={index} xs={12} shadow="round">
              <Typography variant="body1" style={{color:theme.palette.text.primary}}>{post}</Typography>
            </AsideGridText>
          ))}
          <AsideGridText xs={12} color="blue" shadow="round">
            <Typography variant="body1" color="blue">
              More...
            </Typography>
          </AsideGridText>

          {/* Categories */}
          <AsideGridHeading item xs={12}>
            <Typography variant="h2" style={{color:theme.palette.text.primary}}>{title2}</Typography>
          </AsideGridHeading>
          {[category1, category2, category3, category4].map(
            (category, index) => (
              <AsideGridPost key={index} item xs={12}>
                <Checkbox />
                <Typography variant="body1" style={{color:theme.palette.text.primary}}>{category}</Typography>
              </AsideGridPost>
            )
          )}

          {/* Featured Tags */}
          <AsideGridHeading item xs={12}>
            <Typography variant="h2" style={{color:theme.palette.text.primary}}>Featured Tags</Typography>
          </AsideGridHeading>
          <ChipWrapper>
            {[tag1, tag2, tag3, tag4, tag5].map((tag, index) => (
              <Chip key={index}>{tag}</Chip>
            ))}
          </ChipWrapper>

          {/* Related Posts */}
          <AsideGridHeading item xs={12}>
            <Typography variant="h2" style={{color:theme.palette.text.primary}}>{title3}</Typography>
          </AsideGridHeading>
          {[rel1, rel2].map((relatedPost, index) => (
            <AsideGridText  color={theme.palette.text.primary} key={index} xs={12} shadow="round">
              <Typography variant="body1" style={{color:theme.palette.text.primary}}>{relatedPost}</Typography>
            </AsideGridText>
          ))}
          <AsideGridText xs={12} color="blue" shadow="round">
            <Typography variant="body1" color="blue">
              More...
            </Typography>
          </AsideGridText>
        </AsideGrid>
      </Grid>
    </Grid>
  );
};

export default BlogList;