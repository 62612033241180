import { createAsyncThunk } from "@reduxjs/toolkit";
import { Testimony } from "../../utils/types/testimonyManager";
import { collection, deleteDoc, doc, DocumentSnapshot, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const gettestimonies = createAsyncThunk<
  Testimony[],
  void,
  { rejectValue: string }
>("testimonies/get", async (_, { rejectWithValue }) => {
  try {
    const testimonySnapshot = await getDocs(collection(db, "testimonies"));
    const testimonies = testimonySnapshot.docs.map((testimony) =>
      Testimony.fromMap(testimony as DocumentSnapshot)
    ) as unknown as Testimony[];
    return testimonies;
  } catch (error) {
    console.error(error);
    return rejectWithValue(JSON.stringify(error));
  }
});




export const addtestimony = createAsyncThunk<
  Testimony,
  Testimony,
  { rejectValue: string }
>("testimonies/add", async (newEvent, { rejectWithValue }) => {
  try {
    const eventRef = doc(db, "testimonies", newEvent.testimonyId);
    await setDoc(eventRef, newEvent);
    return newEvent;
  } catch (error) {
    console.error("Failed to add testimony", error);
    return rejectWithValue("Failed to add testimony");
  }
});

// Update an existing event
export const updatetestimony = createAsyncThunk<
  Testimony,
  { id: string; data: Partial<Testimony> },
  { rejectValue: string }
>("testimonies/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const eventRef = doc(db, "testimonies", id);
    await updateDoc(eventRef, data);
    const updatedDoc = await getDoc(eventRef);
    return { eventId: id, ...updatedDoc.data() } as unknown as Testimony;
  } catch (error) {
    console.error("Failed to update testimony", error);
    return rejectWithValue("Failed to update testimony");
  }
});

// Delete an event
export const deletetestimony = createAsyncThunk<
  { message: string; eventId: string },
  string,
  { rejectValue: string }
>("testimonies/delete", async (eventId, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, "testimonies", eventId));
    return { message: "Event deleted successfully", eventId };
  } catch (error) {
    console.error("Failed to delete testimony", error);
    return rejectWithValue("Failed to delete testimony");
  }
});
