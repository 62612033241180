import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Testimony } from "../../utils/types/testimonyManager";

// Import the Thunks you created (assuming they are in the same file)
import {
  gettestimonies,
  addtestimony,
  updatetestimony,
  deletetestimony,
} from "./testimonyService"; // Adjust the path if necessary
// Define the state structure
interface PostState {
    testimonies: Testimony[];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: string | null;
    successMessage: string | null;
  }
const initialState:PostState = {
    testimonies: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
    successMessage: null,
  };

  
export const testimonySlice = createSlice({
  name: "testimonies",
  initialState,
  reducers: {    resetState: (state) => {
    state.isLoading = false;
    state.isSuccess = false;
    state.isError = false;
    state.error = null;
    state.successMessage = null;
  },}, // No additional reducers needed in this case
  extraReducers: (builder) => {
    builder
      // Get testimonies
      .addCase(gettestimonies.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(gettestimonies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonies = action.payload;
      })
      .addCase(gettestimonies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload ||'';
      })

      // Add testimony
      .addCase(addtestimony.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(addtestimony.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonies.push(action.payload);
        state.successMessage = "Testimony added successfully";
      })
      .addCase(addtestimony.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload ||'';
      })

      // Update testimony
      .addCase(updatetestimony.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(updatetestimony.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.testimonies.findIndex(
          (testimony) => testimony.testimonyId === action.payload.testimonyId
        );
        if (index !== -1) {
          state.testimonies[index] = action.payload;
        }
        state.successMessage = "Testimony updated successfully";
      })
      .addCase(updatetestimony.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload ||'';
      })

      // Delete testimony
      .addCase(deletetestimony.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(deletetestimony.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.testimonies = state.testimonies.filter(
          (testimony) => testimony.testimonyId !== action.payload.eventId
        );
        state.successMessage = "Testimony deleted successfully";
      })
      .addCase(deletetestimony.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload||'';
      });
  },
});

// Export the reducer and any actions (if needed)
export default testimonySlice.reducer;