import { GalleryContainer, GalleryText, GalleryTitle } from "./gallery.styles";
import Gallerycomp from "./galleryComponent";

export default function Gallery() {
  return (
    <GalleryContainer>
      <GalleryTitle>Our Gallery</GalleryTitle>
      <GalleryText>
        Experience the joy and community of our church through our photo
        gallery,showcasing moments from worship services,outreach events and
        fellowship gatherings
      </GalleryText>
      <Gallerycomp />
    </GalleryContainer>
  );
}
