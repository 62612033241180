import { Box, Card, styled } from "@mui/material";
import img1 from "../../../../assets/images/4.jpg";

export const RevolutionCard = styled(Card)(({ theme }) => ({
  borderRadius: "15px",
  display: "flex",
  flexDirection: "row-reverse",
  width: "80%",
  maxWidth: "1200px",
  height: "70vh",
  margin: "2rem auto",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    height: "auto",
    width: "90%",
  },
}));

export const RevCardImage = styled(Box)(({ theme }) => ({
  width: "50%",
  height: "100%",
  backgroundImage: `url(${img1})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "15px 0 0 15px",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "50vh",
    borderRadius: "15px 15px 0 0",
  },
}));