import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

// Import your reducers
import authReducer from "./auth/authSlice";
import ContactFormReducer from "./contactForm/contactFormSlice";
import faqsReducer from "./faqManagement/questions/questionsSlice";
import faqCategoriesReducer from "./faqManagement/categories/categoriesSlice";
import contactsReducer from "./contacts/contactsSlice";
import usersReducer from "./userManagement/userManagementSlice";
import newslettersReducer from "./newsletters/newslettersSlice";
import newsLetterReducer from "./newsLetterSubscription/newsLetterSlice";
import examRestReducer from "./examManager/examManagerSlice";
import blogPostsReducer from "./blogManagement/posts/postsSlice";
import blogCategoriesReducer from "./blogManagement/categories/categoriesSlice";
import blogTagsReducer from "./blogManagement/tags/tagsSlice";
import classReducer from "./classManager/classSlice";
import eventsReducer from "./eventsManager/eventsSlice";
import postReducer from "./postManagement/postSlice";
import testimonyreducer from "./testimonyManager/testimonySlice";

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  newsLetter: newsLetterReducer,
  users: usersReducer,
  classes: classReducer,
  newsletters: newslettersReducer,
  faqCategories: faqCategoriesReducer,
  faqs: faqsReducer,
  ContactForm: ContactFormReducer,
  contacts: contactsReducer,
  examResults: examRestReducer,
  blogPosts: blogPostsReducer,
  blogCategories: blogCategoriesReducer,
  blogTags: blogTagsReducer,
  events: eventsReducer,
  posts: postReducer,
  testimonies:testimonyreducer
});

// Function to load state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined; // Return undefined to let Redux initialize with default state
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Error loading state from localStorage:", err);
    return undefined;
  }
};

// Function to save state to local storage
const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Error saving state to localStorage:", err);
  }
};

// Middleware to check serialization
const ensureSerializableMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  try {
    // Attempt to serialize the new state
    const state = storeAPI.getState();
    JSON.stringify(state); // Throws error if state is not serializable
  } catch (err) {
    console.error("State is not serializable:", err);
  }

  return result;
};

// Load initial state from local storage
const preloadedState = loadState();

// Configure store with middleware
export const store = configureStore({
  reducer: rootReducer,
  preloadedState, // Use preloaded state if available
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true, // Enable Redux Toolkit's built-in serializable check
    }).concat(ensureSerializableMiddleware), // Add custom middleware
});

// Subscribe to store changes and save state on every update
store.subscribe(() => {
  saveState(store.getState());
});

// Types for state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Typed versions of useDispatch and useSelector
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;