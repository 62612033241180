import { Box, Card, styled, Typography } from "@mui/material";

export const AboutReviewContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  maxWidth: "1200px",
  borderRadius: "20px",
  padding: "2rem",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  margin: "0 auto",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    padding: "1rem",
  },
}));

export const AboutReviewTitle = styled(Typography)(({ theme }) => ({
  marginBottom: "1rem",
  fontSize: "1.8rem",
  fontWeight: "bold",
  fontFamily: "serif",
  textAlign: "center",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
}));

export const AboutReviewText = styled(Typography)(({ theme }) => ({
  marginBottom: "2rem",
  fontSize: "1.2rem",
  fontWeight: "500",
  fontFamily: "serif",
  textAlign: "center",
  color: theme.palette.text.primary,
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
}));

export const AboutReviewCol = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  textAlign: "center",
}));

export const AboutReviewImage = styled(Box)(({ theme }) => ({
  height: "60px",
  width: "60px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  marginRight: "1rem",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

export const AboutReviewRow = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: theme.palette.background.default,
  borderRadius: "15px",
  padding: "1rem",
  width: "30%",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  marginBottom: "1rem",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",width: "100%",
  },
}));

export const AboutReviewsRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "1rem",
  width: "100%",
  marginTop: "1rem",flexDirection:'row',
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));