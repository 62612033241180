import img1 from "../../assets/blog/blog1.png";
import img2 from "../../assets/blog/blog2.png";
import img3 from "../../assets/blog/blog3.png";
import img4 from "../../assets/blog/blog4.png";
import author1 from "../../assets/blog/author1.png";
import author2 from "../../assets/blog/author2.png"; 

interface Blog{
  img:string,
  blogHeading:string,
  postedby : string,
  time:string,
  views:string,
  discreption: string
id: string
}


const AnnouncentData:Blog[]= [
  {id:'1', 
    img: img1,
    blogHeading: "Upcoming Revival Service ", 
    postedby: "Pastor John Doe",
    time: "12:30 am",
    views: "900 views",
    discreption:
      "Join us on Friday,December 15th,at 7pm for our upcoming revival service,featuring guest Speaker Pastor S.M sMITH.Come exoecting a powerful move of God ",
  },
  {id:'2',
    img: img2,
    blogHeading: "Women ministry christmas outreach",
    postedby: "Sis. Mary Johnson",
    time: "2:30 am",
    views: "9300 views",
    discreption:
      "The women's ministry is organizing a christmas outreach program to support local families in need,Donations of food,clothing and toys are welcome",
  },
  {id:'3',
    img: img3,
    blogHeading: "Serving others: a key to spiritual growth",
    postedby: "Admin",
    time: "12:30 pm",
    views: "1500 views",
    discreption:
      "Serving others  is an essential part in our christian walk.Join us as we explore the importance of service as it fortifies our spiritual growth",
  }, 
  {id:'4',
    img: img4,
    blogHeading: "Finding Hope in the Midst of Suffering ",
    postedby: "Admin",
    time: "12:30 am", 
    views: "500 views",
    discreption:
      "As Christians we often face trials and tribulations. But in the midst of suffering,we can find hope in God's promises.Join us as we explore the Bible's teaching on  hope and perserverance ",
  },
];
export default AnnouncentData;

export const TopannData = [
    { 
        img: author1,
        blogHeading: "Upcoming Revival Service ",
        postedby: "Pastor John Doe",
        time: "12:30 am",
        views: "900 views",
        discreption:
          "Join us on Friday,December 15th,at 7pm for our upcoming revival service,featuring guest Speaker Pastor S.M sMITH.Come exoecting a powerful move of God ",
      },
      {
        img: author2,
        blogHeading: "Women ministry christmas outreach",
        postedby: "Sis. Mary Johnson",
        time: "2:30 am",
        views: "9300 views",
        discreption:
          "The women's ministry is organizing a christmas outreach program to support local families in need,Donations of food,clothing and toys are welcome",
      },
];
