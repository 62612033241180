import { Grid } from '@mui/material';
import img1 from '../../../assets/images/hi.jpg'
import img2 from '../../../assets/images/7.jpg'
import img3 from '../../../assets/images/3.jpg'
import { PastorsRow } from './pastors.styles';
import PastorsCard from './pastorsCard';



interface PastorCard{
    id:number;
    title:string;
    description:string;
    image:string;
    path:string;
}

const events:PastorCard[]=[
    {id:1
        ,title:'Prophet T.T Aldrian',
        description:'Major seer of T.E.C ',
        path:'/pastor/1',
        image:`url(${img1})`
    },
    {id:2
        ,title:'Pastor Emmanuel',
        description:'Senior Pastor of T.E.C Buea',
        path:'/pastor/2',
        image:`url(${img2})`
    },
    {id:3
        ,title:'Pastor Joel',
        description:'Senior Pastor of T.E.C Muyuka',
        path:'/pastor/3',
        image:`url(${img3})`
    },
    {id:4
        ,title:'Pastor Fon',
        description:'Senior Pastor of T.E.C Dubai',
        path:'/pastor/4',
        image:`url(${img3})`
    }
];
const PastorsList =()=>{
    return(
      
            <PastorsRow>
                {events.map((event, index)=>(
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <PastorsCard event={event} />
                    </Grid>
                ))}
            </PastorsRow>
        
    )
}
export default PastorsList;