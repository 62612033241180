import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react"

interface ContentFormProps{
    type:string
}

const ContentForm : React.FC<ContentFormProps> =({type}) =>{
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState<File | null>(null);

    const handleFileChange = (event:React.ChangeEvent<HTMLInputElement>)=>{
        if(event.target.files && event.target.files.length > 0){
            setFile(event.target.files[0]);
        }
    };

    const handleSubmit = (event : React.FormEvent)=>{
        event.preventDefault();
        console.log({type,name,title,description,file});
        alert('${type} added successfully');
        setName('');
        setTitle('');
        setDescription('');
        setFile(null);
    };
    return(
<Box 
component='form'
onSubmit={handleSubmit}
sx={{
display:'flex',
flexDirection :'column',
gap:2,maxWidth:600,margin:'0 auto', 
m : 4
}}
>
    <Typography variant="h4" textAlign='center'>Add a {type}</Typography>
    <TextField label='Name' value={name} onChange={(e)=>setName(e.target.value)} required />
    <TextField label='Title' value={title} onChange={(e)=>setTitle(e.target.value)} required />
    <TextField label='Description' value={description} onChange={(e)=>setDescription(e.target.value)} required multiline rows={4} />
    <Button variant="contained" component='label'>
        <input type="file" hidden onChange={handleFileChange} accept="image/" />
Select a file
    </Button>

    <Button type="submit" variant="contained" color="primary">Submit</Button>



</Box>
    )

}

export default ContentForm;