import { Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import { CounterColContainer, Counternumber, Countertitle } from "./counter.styles";
interface CounterProps {
    value: number;
    title : string;
}

const Counter :React.FC<CounterProps>=({title,value})=>{
    const [count, setCount] = useState(0);
    useEffect(()=>{
        const intervalid = setInterval(()=>{
            if(count < value){
                setCount(count + 1);

            } else{
                clearInterval(intervalid);
            }

        },10
    
    
    
    );
    return()=> clearInterval(intervalid)
    },[count,value]);
    return(
        <CounterColContainer><Counternumber>{count}</Counternumber>
        <Countertitle>{title}</Countertitle>
        </CounterColContainer>
    )
}

export default Counter; 