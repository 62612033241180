import { Grid } from "@mui/material";
import img1 from "../../../assets/images/4.jpg";
import Blog from "./blog";
import { BlogContainer, BlogText, BlogTitle } from "./blogs.styles";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useEffect } from "react";
import { fetchPosts } from "../../../redux/postManagement/postSlice";

const Blogs: React.FC = () => {
  const { posts } = useAppSelector((state) => state.posts);
const dispatch = useAppDispatch();

useEffect(()=>{
    dispatch(fetchPosts())
}, [dispatch]);

  return (
    <BlogContainer>
      <BlogTitle>Our Latest Blogs</BlogTitle>
      <BlogText>Check out our recent blogs and articles to stay tuned</BlogText>
      <Grid container spacing={1} style={{ marginLeft: "5%" }}>
        {posts.map((blog, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Blog
              {...{
                comments: 5,
                postmedia: blog.postmedia,
                date: new Date(blog.publishdate).toDateString(),
                title: blog.posttitle,
                description: blog.postcontent,
                likes: blog.postlike.length,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </BlogContainer>
  );
};
export default Blogs;
