import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import bg from "../../contactPage/assests/Hero.jpg";
export const Background = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${bg})`,
  backgroundRepeat: "no-repeat",
  height: '80px',
  marginTop: "10px",
  backgroundSize: "cover",width:"100%",

  [theme.breakpoints.down("md")]: {
    padding: "1rem 0px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "1rem 0px",
  },
  "& h2": {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "25px",
    lineHeight: "54px",
    color: "#ffffff",
    position: "relative",
 
    "&::after": {
      content: `""`,
      position: "absolute",
      top: "43px",
      left: "25px",
      width: "90px",
      height: "5px",
      borderRadius: "10px",
      backgroundColor: "white",
    },
  },
 
}));
