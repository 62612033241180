import { Box, Divider, styled, Typography } from "@mui/material";

export const AboutRevContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',  backgroundColor:theme.palette.background.default,
}));

export const AboutRevRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2%',
    marginLeft: '25%',  backgroundColor:theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack elements vertically on smaller screens
        alignItems: 'center',
        marginLeft: '0%',
    },
}));

export const AboutRevLine = styled(Divider)(({ theme }) => ({
    color: '#06f',
    backgroundColor: 'blueViolet',
    height: '1vh',
    width: '10%',
    paddingLeft: '4%',
    marginTop: '2%',
    marginRight: '2%',
    marginLeft: '1%',
    [theme.breakpoints.down('md')]: {
        width: '80%', // Adjust width for smaller screens
    },
}));

export const AboutRevTitle = styled(Typography)(({ theme }) => ({
    marginTop: '0%',
    paddingBottom: '10%',
    fontSize: '2.3rem',
    fontWeight: 'bold',
    fontFamily: 'serif',
    textAlign: 'center',
    color: 'blueViolet',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        marginTop: '2rem',
    },
}));

export const MissionContainer = styled(Box)(({ theme }) => ({
    boxShadow: '2px 3px 3px 4px rgba(0,0,0,0.2)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2%',
    backgroundColor: 'blueviolet',
    color: 'white',
    fontSize: '1.5rem',
    height: '20vh',
    marginBottom: '2%',
    textAlign: 'center',
    padding: '3%',
    fontFamily: 'serif',animation:'ease 1s', 
    '@keyframes ease':{
        '0%':{
            opacity:0
        }
        ,   '100%':{
            opacity:1
        }
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem', // Adjust font size for smaller screens
        height: '15vh', // Adjust height for smaller screens
    },
}));