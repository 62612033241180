import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import * as React from 'react';

interface ServiceCardProps{
    title:string;
    text :string;
    icon :React.ReactNode;
    image : string;
}


const ServiceCard : React.FC<ServiceCardProps>=({title,text,icon,image})=>{
    const [isExpanded, setIsExpanded] = React.useState(false);

    return(
        <Card sx={
            {
                maxWidth: 345,margin:2
            }
        }>
            <CardActionArea onClick={()=>setIsExpanded(!isExpanded)}>
                <CardContent>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        {icon}
                        <Box sx={{marginLeft:1}} >
                            <Typography gutterBottom variant='h5' component='div' sx={{'@media(max-width:768px)':{fontSize:'1rem'}}}>{title}</Typography>
                            <Typography variant='body2' color='text.primary' sx={{'@media(max-width:768px)':{fontSize:'0.8rem'}}}>{text}</Typography>
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
            {isExpanded &&(
                <CardMedia
                component='img'
                height='140'
                image={image}
                alt={title}/>
            )}
        </Card>
    )
}
export default ServiceCard;