import { Background } from "../../components/HomePage/HomeContainer.style";
import Blogs from "../components/blogs/blogs";
import CounterList from "../components/counter/counterList";
import Gallery from "../components/gallery/gallery";
import Giving from "../components/giving/giving";
import ChurchHero from "../components/hero/churchHero";
import Intro from "../components/intro/intro";
import Join from "../components/join/join";
import Pastors from "../components/pastors/pastors";
import Services from "../components/service/service";
import UpcomingEvents from "../components/upcomingevents/upcomingEvents";

export default function ChurchHomePage() {
  return (
    <Background>
      <ChurchHero />
      <Intro />
      <UpcomingEvents />
      <Giving />
      <Pastors />
      <Gallery />
      <CounterList />
      <Join />
      <Services />
      <Blogs />
    </Background>
  );
}
