import { Grid } from '@mui/material';
import img1 from '../../../assets/images/hi.jpg'
import img2 from '../../../assets/images/7.jpg'
import img3 from '../../../assets/images/3.jpg'
import { CardContainer, CardRow } from './upcomingEvents.styles';
import EventCard from './upcomingEventsCard';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useEffect } from 'react';
import { getEvents } from '../../../redux/eventsManager/eventService';


interface Upcomingevent{
    id:number;
    title:string;
    description:string;
    image:string;
    path:string;
}


const EventsList =()=>{
    const dispatch = useAppDispatch();
  const {events} = useAppSelector((state)=>state.events);

  useEffect(()=>{

    dispatch(getEvents());
  }, [dispatch]);
    return(
        <CardContainer>
            <CardRow>
                {events.map((event, index)=>(
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <EventCard event={event} />
                    </Grid>
                ))}
            </CardRow>
        </CardContainer>
    )
}
export default EventsList;
