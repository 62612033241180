import {
    DocumentData,
    DocumentSnapshot,
    Timestamp,
  } from "firebase/firestore";
import { PostMedia } from "./postManagement";
  

export class Testimony {
    
  testimonyId: string;
  memberId: string; 
  title: string;
  content: string;
  date: Date;
  tags: string[];
  isFeatured: boolean;
  imageUrl: string;
  isApproved: boolean;
  approvalDate: Date;
  files: Array<PostMedia>;

  constructor(data: {
    testimonyId: string;
    memberId: string;
    title: string;
    content: string;
    date: Date;
    tags: string[];
    isFeatured: boolean;
    imageUrl: string;
    isApproved: boolean;
    approvalDate: Date;
    files: Array<PostMedia>;
  }) {
    this.testimonyId = data.testimonyId;
    this.memberId = data.memberId;
    this.title = data.title;
    this.content = data.content;
    this.date = data.date;
    this.tags = data.tags;
    this.isFeatured = data.isFeatured;
    this.imageUrl = data.imageUrl;
    this.isApproved = data.isApproved;
    this.approvalDate = data.approvalDate;
    this.files = data.files;
  }

  toMap():  Record<string, any>  {
    return {
      testimonyId: this.testimonyId,
      memberId: this.memberId,
      title: this.title,
      content: this.content,
      date: this.date,
      tags: this.tags,
      isFeatured: this.isFeatured,
      imageUrl: this.imageUrl,
      isApproved: this.isApproved,
      approvalDate: this.approvalDate,
      files: this.files,
    };
  }

  static fromMap(snapshot: DocumentSnapshot<DocumentData>): Testimony {
    const data = snapshot.data();
    if (!data) {
      throw new Error('No data found in snapshot');
    }
    return new Testimony({
      testimonyId: data.testimonyId,
      memberId: data.memberId,
      title: data.title,
      content: data.content,
      date: data.date,
      tags: data.tags,
      isFeatured: data.isFeatured,
      imageUrl: data.imageUrl,
      isApproved: data.isApproved,
      approvalDate: data.approvalDate,
      files: data.files,
    });
  }
}