import React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import MenuIcon from "@mui/icons-material/Menu";
import { FaHome, FaInfoCircle, FaPhoneSquare, FaDownload, FaChurch, FaTv, FaNewspaper } from "react-icons/fa";
import logo from "../../../assets/images/tec.png";
import mobilelogo from "../../../assets/images/tec.png";
import { AppbarBrand } from "./Navbar.styles";

const NavbarContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  position: "sticky",
  top: 0,
  zIndex: 1000,
}));

const NavbarLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  fontSize: "16px",
  fontWeight: 500,
  padding: "10px 15px",
  borderRadius: "5px",
  transition: "all 0.3s ease-in-out",
  display: "flex",
  alignItems: "center",
  gap: "8px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const DropdownMenuContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  "&:hover .dropdown-menu": {
    display: "block",
  },
}));

const DropdownMenuContent = styled(Box)(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
  borderRadius: "5px",
  minWidth: "150px",
  overflow: "hidden",

  "& a": {
    color: theme.palette.text.primary,
    textDecoration: "none",
    padding: "10px 15px",
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
  },
}));

const Navbar: React.FC<any> = ({
  toggle,
  setToggle,
  toggleTheme,
  setToggleTheme,
  light,
  setLight,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("tablet"));
  const handleThemeToggle = () => {
    setLight(!light);
    setToggleTheme(!toggleTheme);
  };

  return (
    <NavbarContainer>
      <Toolbar sx={{ justifyContent: "space-between", padding: "0 20px" }}>
        {/* BRAND LOGO */}
        <AppbarBrand>
          {isMobile ? (
            <Link to={"/"}>
              <img
                src={mobilelogo}
                alt="Tec Global"
                style={{ height: "7vh", width: "70%" }}
              />
            </Link>
          ) : (
            <Link to={"/"}>
              <img src={logo} alt="Tec Global" />
            </Link>
          )}
        </AppbarBrand>

        {/* NAVIGATION LINKS */}
        {!isMobile && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <NavbarLink to="/">
              <FaHome /> Home
            </NavbarLink>
            
            {/* CHURCH DROPDOWN */}
            <DropdownMenuContainer>
              <NavbarLink to="/church">
                <FaChurch /> Church
              </NavbarLink>
              <DropdownMenuContent className="dropdown-menu">
                <Link to="/church/ministries">Ministries</Link>
                <Link to="/church/event">Events</Link>
                <Link to="/church/departments">Departments</Link>
                <Link to="/church/partnership">Partnership</Link>
                <Link to="/church/testimonies">Testimonies</Link>
                <Link to="/church/give">Give</Link>
                <Link to="/church/about">About</Link>
              </DropdownMenuContent>
            </DropdownMenuContainer>
              
                 <DropdownMenuContainer>
              <NavbarLink to="/tv">
                <FaTv /> TV
              </NavbarLink>
              <DropdownMenuContent className="dropdown-menu">
                <Link to="/church/ministries">Ministries</Link>
                <Link to="/church/events">Events</Link>
                <Link to="/church/groups">Groups</Link>
              </DropdownMenuContent>
            </DropdownMenuContainer>
            <NavbarLink to="/about">
              <FaInfoCircle /> About
            </NavbarLink>
            <NavbarLink to="/blog">
              <FaNewspaper /> Blog
            </NavbarLink>
            <NavbarLink to="/contact">
              <FaPhoneSquare /> Contact
            </NavbarLink>
            <NavbarLink to="/download">
              <FaDownload /> Download
            </NavbarLink>

          </Box>
        )}

        {/* RIGHT ACTION BUTTONS */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Button
            onClick={handleThemeToggle}
            sx={{
              background: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: "20px",
              textTransform: "none",
              "&:hover": {
                background: theme.palette.primary.dark,
              },
            }}
          >
            {light ? <LightModeIcon /> : <DarkModeIcon />}
          </Button>
          {matches && (
            <>
              {isMobile ? (
                <IconButton onClick={() => setToggle(!toggle)}>
                  <MenuIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setToggle(!toggle)}>
                  <MenuIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </NavbarContainer>
  );
};

export default Navbar;