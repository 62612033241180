import React from "react";
import { Background } from "./HeroSec.style";
import { Typography } from "@mui/material";
const HeroSec = () => {
  return (
    <>
      <Background>
        <Typography  variant="h2">Contact Us</Typography>
      </Background>
    </>
  );
};

export default HeroSec;
