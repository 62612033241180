import img1 from "../../../assets/blog/blog1.png";
import img2 from "../../../assets/blog/blog2.png";
import img3 from "../../../assets/blog/blog3.png";
import img4 from "../../../assets/blog/blog4.png";
import author1 from "../../../assets/blog/author1.png";
import author2 from "../../../assets/blog/author2.png";

export interface Blog{
  img:string,
  blogHeading:string,
  postedby : string,
  time:string,
  views:string,
  discreption: string
id: number
}


const BlogData: Blog[]= [
  {id:1, 
    img: img1,
    blogHeading: "Understanding the Bible : Beginner's guide ",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "The Bible is a double edged sword. Studying your Bible everyday, brings you closer to God. Join us as we take lessons on how to better understand the Bible ",
  },
  {id:2,
    img: img2,
    blogHeading: "The Power of Prayer in our lives",
    postedby: "Admin",
    time: "2:30 am",
    views: "300 views",
    discreption:
      "Prayer is a powerful tool that can transform our lives.Join us we explore the importance of prayer and how it can help us deepen our relationship with God",
  },
  {id:3,
    img: img3,
    blogHeading: "Serving others: a key to spiritual growth",
    postedby: "Admin",
    time: "12:30 pm",
    views: "1500 views",
    discreption:
      "Serving others  is an essential part in our christian walk.Join us as we explore the importance of service as it fortifies our spiritual growth",
  }, 
  {id:4,
    img: img4,
    blogHeading: "Finding Hope in the Midst of Suffering ",
    postedby: "Admin",
    time: "12:30 am", 
    views: "500 views",
    discreption:
      "As Christians we often face trials and tribulations. But in the midst of suffering,we can find hope in God's promises.Join us as we explore the Bible's teaching on  hope and perserverance ",
  },
];
export default BlogData;

export const TopData = [
  {
    img: author1,
    blogHeading: "Morgan Andrew",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
  {
    img: author2,
    blogHeading: "Tommy Cruz",
    postedby: "Admin",
    time: "12:30 am",
    views: "500 views",
    discreption:
      "vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex. vehicula, sodales. non, quis nulla, libero, ipsum nulla, elit lacus sit amet, ac ex non, vitae tempor leo. ex est. nec vitae eu volutpat est. ac faucibus nibh. Non odio eget scelerisque volutpat quam lacus ex Cras Ut ex.  ",
  },
];
