import { AboutCard, AboutCol, AboutContainer, AboutImage, AboutText, AboutTitle } from "./about.styles";
import img1 from '../../../assets/images/hi.jpg'
import img2 from '../../../assets/images/7.jpg'
import img3 from '../../../assets/images/3.jpg'
import { useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
interface AboutData{
    image:string;
    title:string;
    text:string;
    category : string;
}
const data : AboutData[]=[
    {category:'Church History',
        image : img1,
        title : 'Church History',
        text:'The Empowerment Church was created in 2017 by our visioneer Prophet Tembeck Tiku Aldrian , who sought the face of the Lord. Together with a committed'
    },
    {
        image :img2,
        title : 'Our Mission',
        text :'Empowering believers, transforming lives and impacting our community for Christ',
        category:'Mission'
    },
    {category:'Vision',
        image :img3,
        title : 'Our Vision',
        text :'Building a community of faith,hope,love,where people can grow in their relationship with '
    }
]

const content = ['Church History', 'Mission', 'Vision']
const About:React.FC =()=>{
    const [selectedCategory,setSelectedCategory] =useState<string>('Church History');

    const handleCategoryChange = (event:React.SyntheticEvent, newValue:string)=>{
        setSelectedCategory(newValue)
    };
    const filteredImages = data.filter(image => image.category===selectedCategory);
    return(<><AboutContainer >
         <AboutTitle>About Us</AboutTitle>
          <Tabs variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile 
             value={selectedCategory} onChange={handleCategoryChange} sx={{backgroundColor:'rgba(138,43,226,0.2)',alignContent:'center',paddingLeft:'30%',marginTop:'2%',fontWeight:'bolder','@media(max-width:768px)':{paddingLeft:'10%'},
             "&:hover":{color:'white'},}}>
                {content.map(category =>(
                    <Tab key={category} label={category.charAt(0).toUpperCase() + category.slice(1)}  value={category} />
                ))}
            </Tabs>
            <Grid container spacing={2} style={{marginTop:'16px'}}>
           {filteredImages.map(image =>(
               <Grid item lg={5} xs={12} sm={6} md={4}>
                <AboutCard><AboutCol><AboutImage style={{backgroundImage:image.image}}  ><img src={image.image} alt=""/></AboutImage> 
                    <AboutTitle>{image.title}</AboutTitle>
                    <AboutText>{image.text}</AboutText>
                    
                    </AboutCol></AboutCard>
               </Grid>
            ))}
           </Grid>
        </AboutContainer></>)
}

export default About;