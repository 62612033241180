import img1 from "../../../../assets/blog/blog1.png";
import img2 from "../../../../assets/blog/blog2.png";
import img3 from "../../../../assets/blog/blog3.png";
import img4 from "../../../../assets/blog/blog4.png";
import author1 from "../../../../assets/blog/author1.png";
import author2 from "../../../../assets/blog/author2.png";

interface Blog{
  img:string,
  blogHeading:string,
  postedby : string,
  time:string,
  views:string,
  discreption: string
id: string
}


const TestimonyData= [
  {id:'1', 
    img: img1,
    blogHeading: "Healed from Chronic Pain ",
    postedby: "Sis. Emmanuella John",
    time: "2:30 pm",
    views: "5000 views",
    discreption:
      " I was suffering from chronic  back pain for over  5 years,  but after prayers from  church  leaders ,i  was completely  healed!I  can now live  my  life  without stress  and pain ",
  },
  {id:'2',
    img: img2,
    blogHeading: "Delivered from  Addiction",
    postedby: "Bro. David Owusu",
    time: "5:30 am",
    views: "300 views",
    discreption:
      "I  was struggling  from  addiction for many  years ,but  through the teachings and support of  our church,i  was able  to  overcome  my  addiction and live  a live  of  purpose and freedom",
  },
  {id:'3',
    img: img3,
    blogHeading: "Received  financial breakthrough",
    postedby: "Sis.  Abigail Mensa",
    time: "12:30 pm",
    views: "1500 views",
    discreption:
      "I  was struggling  financially,but after praying and sowing  seeds into  our church,i  received  a financial breakthrough now  i pay my  financial bills with  ease ",
  }, 
  {id:'4',
    img: img4,
    blogHeading: "A New sense of  Purpose ",
    postedby: "Sis. Sarah Taylor",
    time: "12:30 am", 
    views: "500 views",
    discreption:
      "As Christians we often face trials and tribulations. But in the midst of suffering,we can find hope in God's promises.The  teachings and community have  helped  me  to  grow  in  faith and to  live  out values",
  },
];
export default TestimonyData;

export const ToptestifiersData = [
  {
    img:author1,
    blogHeading: "Received  financial breakthrough",
    postedby: "Sis.  Abigail Mensa",
    time: "12:30 pm",
    views: "1500 views",
    discreption:
      "I  was struggling  financially,but after praying and sowing  seeds into  our church,i  received  a financial breakthrough now  i pay my  financial bills with  ease ",
  }, 
  {
    img: author2,
    blogHeading: "A New sense of  Purpose ",
    postedby: "Sis. Sarah Taylor",
    time: "12:30 am", 
    views: "500 views",
    discreption:
      "As Christians we often face trials and tribulations. But in the midst of suffering,we can find hope in God's promises.The  teachings and community have  helped  me  to  grow  in  faith and to  live  out values",
  },
];