import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, Typography, Box, Link, useTheme } from "@mui/material";
import theme from "../styles/theme";
import { Background } from "../components/HomePage/HomeContainer.style";

// Animation for a subtle button hover effect
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;



// Styled container with a modern gradient background and rounded card design
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: linear-gradient(135deg, ${theme.palette.primary.dark}, #fff);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  max-width:100%;

  height: 100%;
  text-align: center;
`;

// Styled button with smooth hover animations
const DownloadButton = styled(Button)`
  && {
    background-color: #1E3A8A;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 25px;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #ff3e30;
      animation: ${pulse} 0.5s ease-in-out;
    }
  }
`;

// Styled typography for better font hierarchy
const Title = styled(Typography)`
  && {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    line-height: 1.5;
  }
`;

const Subtitle = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 25px;
    line-height: 1.8;
  }
`;

// The React Functional Component
const AppDownload: React.FC = () => {
  const googleDriveLink =
    "https://drive.google.com/file/d/14SyBFDhWrVDidsC2Dkbs9S6om123L9f-/view?usp=sharing";

  return (
    <Background>
    <Container>
      <Title variant="h5" >
        Download Our App Now
      </Title>
      <Subtitle variant="body1">
        Experience the best features and performance. Get the app today!
      </Subtitle>
      <Link href={googleDriveLink} underline="none" target="_blank" rel="noopener noreferrer">
        <DownloadButton variant="contained">Download App</DownloadButton>
      </Link>
    </Container></Background>
  );
};

export default AppDownload;