import { Box, Button, Card, styled, Typography } from "@mui/material";

export const PastorsContainer= styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',margin:'0 auto'
}))

export const PastorsTitle= styled(Typography)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const PastorsText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',

    color:theme.palette.text.primary,
    margin:'auto',
    textAlign: 'center',
    width:'80%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));



  
  export const PastorsRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',  minWidth :'50%',maxWidth:'100%', 
    marginTop: '3%',
    marginLeft: '0%',justifyContent:'space-evenly',marginBottom:'6%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column', // Stack cards vertically on smaller screens
        width: '100%',
        marginLeft: '0%',
    },
  }));
  
  export const PastorCardContent = styled(Card)(({ theme }) => ({
    marginLeft: '2%',
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'creamWhite',
    borderRadius: '10px',
    minWidth: '50%',
    maxWidth: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minWidth: '50%',
      maxWidth: '100%',
      width: '95%',marginBottom:'6%'
    },
  }));
  
  export const PastorCardText = styled(Typography)(({ theme }) => ({
    fontSize:'0.9rem',
    [theme.breakpoints.down('md')]: {
        width: '100%', // Make cards full-width on smaller screens
        margin: '10px 0',
    },
  }));
  
  export const PastorCardImage = styled(Box)(({ theme }) => ({
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'blueviolet',
    color: 'white',
   
    height: '23vh',
    width: '100%',
    marginLeft: '0%',
    marginTop: '2%',marginBottom:'2%',
    zIndex: '1','&:hover':{transform:'scale(1.1)',},
    [theme.breakpoints.down('md')]: {
        height: '20vh', // Adjust height for smaller screens
    },
  }));
  
  export const PastorCardTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'start',

    [theme.breakpoints.down('md')]: {
        fontSize: '1.2rem', // Adjust font size for smaller screens
    },
  }));
  
 

  