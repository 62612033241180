import * as React from "react";
import img1 from "../../../assets/images/4.jpg";
import ServiceCard from "./serviceCard";
import { ShoppingBag } from "@mui/icons-material";
import { ServiceContainer, ServiceText, ServiceTitle } from "./service.styles";
import { FaPrayingHands } from "react-icons/fa";

interface Service {
  title: string;
  text: string;
  icon: React.ReactNode;
  image: string;
}

const service: Service[] = [
  {
    title: "Ministries",
    text: "Empowering believers and nurturing growth:Our ministries include childeren,youths,men and women ministries",
    icon: <ShoppingBag />,
    image: `${img1}`,
  },
  {
    title: "Departments",
    text: "Our departments include worship,evangelism, education, community service and administration. Each plays a vital role to disciple and serve",
    icon: <ShoppingBag />,
    image: `${img1}`,
  },
  {
    title: "Online Bible Studies",
    text: "Join our online bible studies for interactive sessions and in-depth teachings.",
    icon: <ShoppingBag />,
    image: `${img1}`,
  },
  {
    title: "Prophetic Sermons",
    text: "Experience inspiring sermons with biblical insights and guidance. Equip,encourage and empower your faith",
    icon: <FaPrayingHands />,
    image: `${img1}`,
  },
];
const Services: React.FC = () => {
  return (
    <div>
      <ServiceContainer>
        <ServiceTitle>Our Services</ServiceTitle>
        <ServiceText>
          Experience spiritual growth and community through our dynamic
          services, featuring inspiring worship,Bible-based teaching and
          meaningfulconnections
        </ServiceText>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {service.map((service) => (
            <ServiceCard key={service.title} {...service} />
          ))}
        </div>
      </ServiceContainer>
    </div>
  );
};

export default Services;
