import { Box, Button, Card, styled, Typography } from "@mui/material";

export const ServiceContainer= styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',margin:'0 auto'
}))

export const ServiceTitle= styled(Typography)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const ServiceText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: theme.palette.text.primary,
    margin:'auto',
    textAlign: 'center',
    width:'80%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));