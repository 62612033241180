import { Box, Card, styled } from "@mui/material";

export const AboutContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'column',margin:'5%',
}))

export const AboutCard = styled(Card)(({theme})=>({
    display:'flex',
    flexDirection:'row',margin:'5% auto 6% 8%',width:'100%',minWidth:'90%',maxWidth:'100%',  backgroundColor:theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
        margin:'1% auto 2% 1%',
    },
}))
export const AboutCol = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column'
}))

export const AboutImage = styled(Box)(({theme})=>({
    marginLeft: '10%',
    padding: '3%',
    height: '45vh',
    backgroundSize: 'cover', // Ensure image scales proportionally
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: '10px',
    minWidth: '70%',
    borderBottomLeftRadius: '10px',
    maxWidth: '100%',
    width: '50%',
    [theme.breakpoints.down('md')]: {
        height: '45vh', width:'100%',borderRadius:'10px',margin:'2% auto'// Adjust height for smaller screens
    },
}))
export const AboutTitle = styled(Box)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: 'blueViolet',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',fontFamily:'serif',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}))

export const AboutText = styled(Box)(({theme})=>({
    fontSize: '1rem',
    fontWeight: '400',
    color: 'black',
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '1%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}))


