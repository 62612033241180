import React from "react";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import {
  ProphetContainer,
  ProphetCol,
  ProphetTitle,
  ProphetText,
} from "../prophet/prophet.styles";
import { RevCardImage, RevolutionCard } from "./revolution.styles";

export default function Revolution() {
  return (
    <ProphetContainer>
      <RevolutionCard>
        <RevCardImage />
        <ProphetCol>
          <ProphetTitle>The Empowerment Revolution</ProphetTitle>
          <ProphetText>
            At the revolution, we are committed to empowering our members to
            live a well-fulfilled life in Christ. The revolution is made up of
            three pillars: The Empowerment Church, The Empowerment School, and
            The Empowerment Foundation, all managed by our general overseer,
            Prophet Tembeck T. Aldrian. Never stop praying! God is always there
            for us all.
          </ProphetText>
          <ProphetCol>
            <ProphetText>
              <FaArrowAltCircleDown
                style={{
                  marginRight: "10px",
                  color: "#e53935",
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.2)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              />
              An Arena to Train Kingdom Militants
            </ProphetText>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{
                  marginRight: "10px",
                  color: "#1976d2",
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.2)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              />
              A Beacon of Hope and Transformation
            </ProphetText>
            <ProphetText>
              <FaArrowAltCircleRight
                style={{
                  marginRight: "10px",
                  color: "#1976d2",
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.transform = "scale(1.2)")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              />
              A Vision for a Brighter Tomorrow
            </ProphetText>
          </ProphetCol>
        </ProphetCol>
      </RevolutionCard>
    </ProphetContainer>
  );
}