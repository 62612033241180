import { DocumentSnapshot } from "firebase/firestore";

// Define TypeScript interface for Post properties
export interface PostProps {
  postid: string;
  posttitle: string;
  postcontent: string;
  postmedia: Array<PostMedia>; // Array of media objects
  postlike: Array<string>; // Array of user IDs who liked the post
  postcoments: Array<object>; // Array of comment objects
  authorName: string;
  authorprofilepicture: string;
  publishdate: Date;
  authorid: string;
  category: string;
}
export interface PostMedia {
    url: string,
    type: string,
    name:string,
    size:string

}

// Post class for managing Firestore data conversions
export class Post {
  postid: string;
  posttitle: string;
  postcontent: string;
  postmedia: Array<PostMedia>;
  postlike: Array<string>;
  postcoments: Array<object>;
  authorName: string;
  authorprofilepicture: string;
  publishdate: Date;
  authorid: string;
  category: string;

  constructor(props: PostProps) {
    this.postid = props.postid;
    this.posttitle = props.posttitle;
    this.postcontent = props.postcontent;
    this.postmedia = props.postmedia;
    this.postlike = props.postlike;
    this.postcoments = props.postcoments;
    this.authorName = props.authorName;
    this.authorprofilepicture = props.authorprofilepicture;
    this.publishdate = props.publishdate;
    this.authorid = props.authorid;
    this.category = props.category;
  }

  // Convert Post instance to Firestore-compatible object
  toFirestore(): Record<string, any> {
    return {
      postid: this.postid,
      posttitle: this.posttitle,
      postcontent: this.postcontent,
      postmedia: this.postmedia,
      postlike: this.postlike,
      postcoments: this.postcoments,
      authorName: this.authorName,
      authorprofilepicture: this.authorprofilepicture,
      publishdate: this.publishdate.getTime(), // Convert Date to timestamp
      authorid: this.authorid,
      category: this.category,
    };
  }

  // Static method to create a Post instance from Firestore data
  static fromFirestore(snapshot: DocumentSnapshot): Post {
    const data = snapshot.data();

    if (!data) {
      throw new Error("No data found in the snapshot");
    }

    // Extract postmedia if it exists
    const postmedia: Array<PostMedia> = [];
    if (Array.isArray(data.postmedia)) {
      data.postmedia.forEach((media: any) => {
        postmedia.push({ ...media });
      });
    }

    return new Post({
      postid: data.postid || "",
      posttitle: data.posttitle || "",
      postcontent: data.postcontent || "",
      postmedia: postmedia,
      postlike: data.postlike || [],
      postcoments: data.postcoments || [],
      authorName: data.authorName || "",
      authorprofilepicture: data.authorprofilepicture || "",
      publishdate: new Date(data.publishdate),
      authorid: data.authorid || "",
      category: data.category || "",
    });
  }
}