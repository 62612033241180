import { Box, Button, styled, Typography } from "@mui/material";
import img1 from "../../../assets/images/give.png";
export const GivingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: `rgba(138,43,226,0.2)`,
  backgroundImage: `url(${img1})`,
  backgroundSize: "contain",
  height: "50vh",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "right",
  paddingBottom: "15%",
  marginBottom: "6%",

  [theme.breakpoints.down("md")]: {
    backgroundSize: "50%",
  },
}));
export const GivingContainercol = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "60%",
  alignContent: "start",
  marginLeft: "5%",
  paddingTop: "3%",
}));

export const GivingTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  textAlign: "start",
  color:theme.palette.text.primary,
  margin: "auto 1%",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
    marginBottom: "2%",
  },
}));

export const GivingText = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  textAlign: "start",
  margin: "auto",  color:theme.palette.text.primary,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
}));

export const GivingButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginTop: "1%",
  marginBottom: "6%",
  fontSize: "1rem",
  height: "15%",
  border: "1px solid white",
  width: "30%",
  minWidth: "10%",
  fontWeight: "bold",
  maxWidth: "30%",
  paddingLeft: "1%",
  marginLeft: "0%",
  background: "linear-gradient(to bottom,#1E3A8A,white)",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "3%", // Make buttons full-width on smaller screens
    marginLeft: "1%",
    height: "6vh",
    fontSize: "0.8rem",
    paddingLeft: "2%",
    paddingTop: "1.5%",
    paddingRight: "1.5%",
    paddingBottom: "3%",
  },
}));
