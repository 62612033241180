import { Box, Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Styled components
const StartContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "50vh",
  padding: "2rem",
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.background.default} 100%)`,
  color: theme.palette.text.primary,
  animation: "fadeIn 1s ease-in-out",
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}));

const StartTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "'Lora', serif",
  fontSize: "3rem",
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.common.white,
  marginBottom: "1.5rem",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
  animation: "slideIn 1s ease-in-out",
  "@keyframes slideIn": {
    "0%": { opacity: 0, transform: "translateY(-50px)" },
    "100%": { opacity: 1, transform: "translateY(0)" },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const StartLink = styled(Button)(({ theme }) => ({
  marginTop: "2rem",
  padding: "0.75rem 2rem",
  borderRadius: "50px",
  fontSize: "1.25rem",
  fontWeight: "700",
  textAlign: "center",
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.dark,
  transition: "all 0.3s ease",
  boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    transform: "scale(1.1)",
    backgroundColor: theme.palette.secondary.dark,
    boxShadow: "0 12px 20px rgba(0, 0, 0, 0.3)",
  },
  "&:active": {
    transform: "scale(0.98)",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
  [theme.breakpoints.down("md")]: {
    width: "60%",
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80%",
    fontSize: "1rem",
  },
}));

// React Component
export default function Start() {
  const navigate = useNavigate();

  return (
    <StartContainer>
      <StartTitle>
        Start A New Life in Christ Today!
      </StartTitle>
      <StartLink
        onClick={() => {
          navigate("/download");
        }}
      >
        Click Here to Begin
      </StartLink>
    </StartContainer>
  );
}