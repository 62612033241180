import { Box, styled, Typography } from "@mui/material";
import coll from '../../../assets/images/coll.jpeg'
export const ChurchEventContainer = styled(Box)(({theme})=>({
    display:'flex',flexDirection:'column'

}))

export const ChurchEventHero = styled(Box)(({theme})=>({
display :'flex',backgroundSize:'90%' ,marginLeft:'15%',marginBottom:'6%',

backgroundImage:`url(${coll})`,backgroundRepeat:'no-repeat',
width:'100%',
minWidth:'50%',maxWidth:'80%',borderRadius:'10px',height:'50vh',
[theme.breakpoints.down('md')]: {
    height:'30vh',
 }
}))
export const ChurchEventHeroOverlay = styled(Box)(({theme})=>({
    display :'flex',
    backgroundColor:'rgba(0,0,0,0.4)',
    width:'100%',
    minWidth:'50%',maxWidth:'90%',borderRadius:'10px',height:'50vh',
    [theme.breakpoints.down('md')]: {
       height:'27vh',borderRadius:'10px'
    }
    }))


    export const ChurchEventHeroTitle = styled(Typography)(({ theme }) => ({
        fontSize: '3rem',
        fontWeight: 'bold',
        color: 'white',
        marginTop: '7%',
        textAlign: 'center',marginLeft:'40%',
        marginBottom: '0%',alignSelf:'center',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',marginLeft:'3% auto',alignContent:'center'
        }
    }));

 