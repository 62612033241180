import {
  IntroButton,
  IntroCol,
  IntroContainer,
  IntroImage,
  IntroText,
  IntroTitle,
} from "./intro.styles";

export default function Intro() {
  return (
    <IntroContainer>
      <IntroCol>
        <IntroTitle>Welcome to T.E.C</IntroTitle>
        <IntroText>
          Welcome to The Empowerment Church,a vibrant community of believers
          passionate about spreading the love of Christ.Our mission is to
          empower individuals,families,and communities through faith,education
          and service
        </IntroText>
        <IntroText>
          Welcome to The Empowerment Church,a vibrant community of believers
          passionate about spreading the love of Christ.Our mission is to
          empower individuals,families,and communities through faith,education
          and service
        </IntroText>
        <IntroButton>Learn More!</IntroButton>
      </IntroCol>
      <IntroImage />
    </IntroContainer>
  );
}
