import React, { useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme, { darkTheme } from "./styles/theme";
import { store } from "./redux/store";
import { GlobalStyles } from "./styles/globals";
import Layout from "./components/Layout/Layout";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/Contact";
import UserManagement from "./pages/dashboard/administrator/settings";
import TeacherManagement from "./pages/dashboard/teacher/settings";
import { UserManagement as UManager } from "./pages/dashboard/administrator/userManagement";
import { login as ULogin } from "./pages/login";
import Email from "./pages/email";

import Faq from "./pages/faq";
import Blog from "./pages/blogs";
import LivePage from "./pages/live";
import GivePage from "./pages/give";
import ChurchHomePage from "./church/routes/ChurchHomePage";
import ChurchAboutPage from "./church/routes/ChurchAboutPage";
import TestimoniesPage from "./church/routes/TestimoniesPage";
import ChurchEventPage from "./church/routes/ChurchEventPage";
import AnnouncementPage from "./church/routes/AnnouncementsPage";
import PublisherPage from "./church/routes/PublisherPage";
import AppDownload from "./pages/download";
export default function App() {
  const [toggleTheme, setToggleTheme] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [light, setLight] = useState(false);

  return (
    <>
      <title>The Empowerment Church International</title>
      <meta name="description" content="The Empowerment Revolution" />

      <ThemeProvider theme={toggleTheme ? darkTheme : theme}>
        <Provider store={store}>
          <Router>
            <Layout
              toggleTheme={toggleTheme}
              setToggleTheme={setToggleTheme}
              toggle={toggle}
              setToggle={setToggle}
              light={light}
              setLight={setLight}
            >
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="about" element={<About />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="dashboard">
                    <Route path="administrator">
                      <Route index element={<UserManagement />} />
                      <Route path="userManagement" element={<UManager />} />
                    </Route>
                    <Route path="teacher" element={<TeacherManagement />} />
                  </Route>
                  <Route path="/give" element={<GivePage />} />
                  <Route path="/live" element={<LivePage />} />
                  <Route path="login" element={<ULogin />} />
                  <Route path="faq" element={<Faq />}></Route>
                 
                  <Route path="email" element={<Email />} />
                  <Route path="blog" element={<Blog />} />

                  <Route path="church">
                    <Route index element={<ChurchHomePage />}></Route>

                    <Route path="about" element={<ChurchAboutPage />} />
                    <Route path="testimonies" element={<TestimoniesPage />} />
                    <Route path="event" element={<ChurchEventPage />} />
                    <Route
                      path="announcements"
                      element={<AnnouncementPage />} 
                    />
                    <Route path="publisher" element={<PublisherPage />} />
                  </Route>

                  <Route path="/download" element={<AppDownload />} />
                </Route>
              </Routes>
              <GlobalStyles />
              <ToastContainer />
            </Layout>
          </Router>
        </Provider>
      </ThemeProvider>
    </>
  );
}
