import { Box, Divider, styled } from "@mui/material";

export const AboutRowContainer = styled(Box)(({theme})=>({
    display:'flex',
    flexDirection:'row',
    minWidth:'50%',width:'60%',maxWidth:'100%',
    paddingBottom: '10%',justifyContent:'space-between',alignItems:'center',alignContent:'center',alignSelf:'center',
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "center",minWidth:'50%',width:'60%',maxWidth:'100%',height:'80vh',margingBottom:'4%'
      },
    
     
}))

export const AboutRowCol=styled(Box)(({theme})=>({
display:'flex',
flexDirection:'column',
textAlign:'center'
}))

export const AboutRowNumber = styled(Box)(({theme})=>({
fontSize:'2rem',color:theme.palette.text.primary,fontWeight:'bold'
}))
export const AboutRowtext = styled(Box)(({theme})=>({
    fontSize:'1rem',color:theme.palette.text.primary
    }))
    export const AboutRowLine = styled(Divider)(({ theme }) => ({
        color: '#06f',
        backgroundColor: theme.palette.grey[100],
        height: '0.2px',
        width: '80%',minWidth:'50%',maxWidth:'100%',alignSelf:'center',
        paddingLeft: '4%',marginTop:'-10vh',
   
        marginRight: '2%',
        marginLeft: '1%',
        [theme.breakpoints.down('md')]: {
            width: '80%',marginTop:'-4vh' // Adjust width for smaller screens
        },
    }));
    