import React from "react";
import { Heading, HeroContainer } from "./Hero.styles";

interface heroprop{
  title: string;
}
const DynamicHero: React.FC<heroprop> =({title})=>{
  return (
    <HeroContainer>
      <Heading variant="h1">{title}</Heading>
    </HeroContainer>
  );
}
export default DynamicHero
