import { Box, Button, Card, styled, Typography } from "@mui/material";

export const BlogContainer= styled(Box)(({theme})=>({ 
    display:'flex',flexDirection:'column',minWidth:'50%',maxWidth:'100%',width:'90%',margin:'0 auto 5%',
    [theme.breakpoints.down('md')]: {
       marginLeft :'0%',
    } 
}))

export const BlogTitle= styled(Typography)(({theme})=>({
    fontSize: '2rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: '1%',
    textAlign: 'center',
    marginBottom: '0%',
    [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
    }
}));
export const BlogText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '400',
    color: theme.palette.text.primary,
    margin:'auto',
    textAlign: 'center',
    width:'80%',paddingBottom:'3%',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.8rem',
    }
}));