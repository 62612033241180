import Cards from "../../../components/tec/home/Cards/card";
import Events from "../../../components/tec/home/events/events";
import Hero from "../../../components/tec/home/Hero/hero";
import HeroTwo from "../../../components/tec/home/herotwo/herotwo";
import Start from "../../../components/tec/home/Start/start";
import { Background } from "../../HomePage/HomeContainer.style";
import Explore from "../about/explore/explore";

export default function HomePage() {
  return (
    <Background>
   <Explore></Explore>
<br></br>
      <HeroTwo />
      <Cards />
      <Events />
   
      <Start />
    </Background>
  );
}
