import { CardContent } from "@mui/material";
import {
  CardTitle,
  CardImage,
  CardContentText,
  UpcomingButton,
} from "./upcomingEvents.styles";
import { Link, useNavigate } from "react-router-dom";
import { EventModelProps } from "../../../utils/types/eventsManager";

interface Props {
  event: EventModelProps;
}

const EventCard: React.FC<Props> = ({ event }) => {
  const navigate = useNavigate();
  const handleCard = (eventId: string) => {
    navigate(`/${eventId}`);
  };
  return (
    <CardContent>
      {" "}
      <CardImage src={event.imageUrl} />
      <CardTitle>{new Date(event.startTime).toDateString()}</CardTitle>
      <CardContentText>{event.description}</CardContentText>
      <UpcomingButton href={""} onClick={() => handleCard(event.eventId)}>
        Read More
      </UpcomingButton>
    </CardContent>
  );
};

export default EventCard;
