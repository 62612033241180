import { Link, useNavigate } from "react-router-dom";
import {
  PastorCardContent,
  PastorCardImage,
  PastorCardText,
  PastorCardTitle,
} from "./pastors.styles";
import { UpcomingButton } from "../upcomingevents/upcomingEvents.styles";

interface PastorCard {
  id: number;
  title: string;
  description: string;
  image: string;
  path: string;
}

interface Props {
  event: PastorCard;
}

const PastorsCard: React.FC<Props> = ({ event }) => {
  const navigate = useNavigate();
  const handleCard = (eventId: number) => {
    navigate("/${eventId}");
  };
  return (
    <PastorCardContent>
      {" "}
      <PastorCardImage style={{ backgroundImage: event.image }} />
      <PastorCardTitle>{event.title}</PastorCardTitle>
      <PastorCardText>{event.description}</PastorCardText>
      <UpcomingButton href={event.path} onClick={() => handleCard(event.id)}>
        Message
      </UpcomingButton>
    </PastorCardContent>
  );
};

export default PastorsCard;
