import { Box, Button, Card, styled, Typography } from "@mui/material";

export const GalleryContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "50%",
  maxWidth: "100%",
  width: "90%",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    minHeight: "70vh", // Adjust font size for smaller screens
  },
}));

export const GalleryTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  marginTop: "1%",
  textAlign: "center",
  marginBottom: "0%",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
}));
export const GalleryText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "400",
  color: theme.palette.text.primary,
  margin: "auto",
  textAlign: "center",
  width: "80%",
  padding: "auto",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8rem",
  },
}));

export const GalleryRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  minWidth: "50%",
  maxWidth: "100%",
  marginTop: "3%",
  marginLeft: "0%",
  marginBottom: "6%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column", // Stack cards vertically on smaller screens
    width: "100%",
    marginLeft: "0%",
  },
}));

export const GalleryImage = styled(Box)(({ theme }) => ({
  backgroundSize: "100%", // Ensure image scales proportionally
  backgroundRepeat: "no-repeat",
  backgroundColor: theme.palette.primary.main,
  color: "white",

  height: "30vh",
  width: "100%",
  marginLeft: "0%",
  marginTop: "2%",
  marginBottom: "2%",
  zIndex: "1",
  "&:hover": { transform: "scale(1.1)" },
  [theme.breakpoints.down("md")]: {
    height: "20vh", // Adjust height for smaller screens
  },
}));
