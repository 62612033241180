import { Box, Card, styled, Typography } from "@mui/material";
import img1 from "../../../../assets/images/1.jpg";
import { backgroundRepeat } from "html2canvas/dist/types/css/property-descriptors/background-repeat";

export const ExploreContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
  height: "100%",
  padding: "2rem",
  position: "relative", // Allows the image to be positioned correctly
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    margin: "0",
    height: "50vh", // Full height for mobile
    padding: theme.spacing(2),
    maxWidth: "100%",
    width: "100%",
  },
}));

export const ExploreLeft = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  width: "50%",
  padding: "3rem",
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, #ffffff)`,
  borderRadius: "12px",
  color: "white",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  zIndex: 2, // Ensures it overlays the image
  [theme.breakpoints.down("md")]: {
    width: "90%",
    marginBottom: "2rem",
    padding: theme.spacing(2),marginTop:'22vh',
    textAlign: "center",
    alignItems: "center",height:"22vh",
 paddingTop:"20vh",  position: "absolute", 
    background: "rgba(0, 0, 0, 0.6)", // Semi-transparent background for contrast
    color: "white",
  },
}));

export const ExploreTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: "bold",
  marginBottom: "1rem",
  color: theme.palette.common.white,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2rem",
    paddingTop: theme.spacing(1),
  },
}));

export const ExploreText = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  marginBottom: "2rem",
  color: theme.palette.common.white,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
}));

export const ExploreCardRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  zIndex: "2",
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
    width: "100%",marginTop:"1vh",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
}));

export const ExploreCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "1.5rem",
  width: "40%",
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  backgroundColor: theme.palette.background.default,
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.2)",
  },
  [theme.breakpoints.down("md")]: {
    
    width: "100%",height:"13vh",
    padding: theme.spacing(2),paddingBottom:theme.spacing(1),
    marginBottom: "1rem",
  },
}));

export const ExploreCol = styled(Box)(({ theme }) => ({
  marginTop: "1rem",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
    marginBottom: "1rem",
  },
}));

export const ExploreCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.text.primary,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.7rem",
  },
}));

export const ExploreImage = styled(Box)(({ theme }) => ({
  width: "45vw",
  height: "85vh",
  borderRadius: "12px",
  backgroundImage: `url(${img1})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    position: "absolute", // Allow it to behave as a background
    top: 0,
    left: 0,
    width: "100%", 
    backgroundSize: "contain",
   // height: "100%", // Occupy 80% of the height
    //zIndex: -1,
 backgroundRepeat:"no-repeat",
    backgroundPosition: "top center ",
    borderRadius: "0", // Remove border radius for seamless appearance
  },
}));