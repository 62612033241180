import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { db } from "../firebase"; // Adjust the path to your Firebase setup
import {
  collection,
  doc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  DocumentSnapshot,
} from "firebase/firestore";
import { EventModel, EventModelProps, EventsState } from "../../utils/types/eventsManager";


// Fetch all events
export const getEvents = createAsyncThunk<
  EventModelProps[],
  void,
  { rejectValue: string[] }
>("events/get", async (_, { rejectWithValue }) => {
  try {
    const eventSnapshot = await getDocs(collection(db, "events"));
    const events = eventSnapshot.docs.map((doc) =>
      EventModel.fromMap(doc as DocumentSnapshot)
    ) as unknown as EventModelProps[];

    return events;
  } catch (error) {
    console.error("Failed to fetch events", error);
    return rejectWithValue(["Failed to fetch events"]);
  }
});

// Add a new event
export const addEvent = createAsyncThunk<
  EventModelProps,
  EventModelProps,
  { rejectValue: string[] }
>("events/add", async (newEvent, { rejectWithValue }) => {
  try {
    const eventRef = doc(db, "events", newEvent.eventId);
    await setDoc(eventRef, newEvent);
    return newEvent;
  } catch (error) {
    console.error("Failed to add event", error);
    return rejectWithValue(["Failed to add event"]);
  }
});

// Update an existing event
export const updateEvent = createAsyncThunk<
  EventModelProps,
  { id: string; data: Partial<EventModelProps> },
  { rejectValue: string[] }
>("events/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const eventRef = doc(db, "events", id);
    await updateDoc(eventRef, data);
    const updatedDoc = await getDoc(eventRef);
    return { eventId: id, ...updatedDoc.data() } as unknown as EventModelProps;
  } catch (error) {
    console.error("Failed to update event", error);
    return rejectWithValue(["Failed to update event"]);
  }
});

// Delete an event
export const deleteEvent = createAsyncThunk<
  { message: string; eventId: string },
  string,
  { rejectValue: string[] }
>("events/delete", async (eventId, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, "events", eventId));
    return { message: "Event deleted successfully", eventId };
  } catch (error) {
    console.error("Failed to delete event", error);
    return rejectWithValue(["Failed to delete event"]);
  }
});
